import React from 'react';
import {Link as RouterLink} from "react-router-dom";

const Link = ({to, children, ...rest}) => {
  if (to.startsWith('http')) {
    return <a href={to} target="_blank" {...rest}>{children}</a>;
  }
  else {
    return <RouterLink to={to} {...rest}>{children}</RouterLink>;
  }
}

export default Link;
