import React, { useState, useEffect } from "react";
import Icon from "@Components/Icon";

const Toast = ({ message, onClose, show, icon = 'close', links = [] }) => {
  const [active, setActive] = useState(show);

  useEffect(() => {
    setActive(show);
  }, [show]);

  const classes = ['toast', 'bg-variant-brand-secondary'];

  if (active) {
    classes.push('show');
    classes.push('toast--active');
  } else {
    classes.push('closable--closed');
  }

  const messageCapitalized = message
		? message.charAt(0).toUpperCase() + message.slice(1)
		: "";

  return (
    <div className={classes.join(' ')} data-rs-toast="3" data-rs-toast-3="">
      <p className="toast__message">{messageCapitalized}</p>
      {links.length > 0 && <div className="toast__cta">
        {links.map((link, key) => {
          const { text, classes = [], ...attr } = link;
          classes.push('toast__cta-link')

          return <a {...attr} key={key} className={classes.join(" ")}>{text}</a>
        })}
      </div>}
      <button className="button--icon-only" data-rs-closable="data-rs-toast-3" onClick={onClose}>
        {icon && (
          <Icon type={icon} className='icon icon fill--off-white-alpha-50' />
        )}
        <span className="hidden--visually">close</span>
      </button>
    </div>
  );
};

export default Toast;
