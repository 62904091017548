import React from "react";
import Modal from "@Components/Modal";

const PrivacyPolicyModal = props => {
  return (
    <Modal
      {...props}
    >
      <p>
        <strong>
          Einwilligung in die Erhebung und Verarbeitung von Daten durch die&nbsp;
          <em>Randstad Gruppe Deutschland.</em>
        </strong>
      </p>
      <p>
        Mir ist bewusst, dass ich mich bei Randstad Deutschland GmbH &amp; Co KG („Randstad“) bei einem mit höchst
        modernen Methoden der IT und des Personalwesens arbeitenden Unternehmen bewerbe. Dazu gehört, dass meine
        Daten, die ich im Rahmen meiner Bewerbung Randstad zur Verfügung stelle, (i) an andere Gesellschaften
        innerhalb der Randstad-Gruppe und (ii) an bestehende sowie potenzielle Kunden von Randstad weitergegeben
        werden.
      </p>
      <p>
        1.&nbsp; &nbsp; Mit Blick darauf willige ich ein, dass meine Daten für den Zweck meiner Bewerbung und dem
        Abschluss eines Arbeitsverhältnisses innerhalb der Randstad Gruppe Deutschland
        <br />
        (dazu gehören: Randstad Automotive GmbH &amp; Co. KG, Randstad Outsourcing GmbH, Randstad Financial Services
        GmbH, Randstad Sourceright GmbH, Randstad N.V., GULP Solution Services GmbH &amp; Co. KG, Tempo-Team
        Outsourcing GmbH, Tempo-Team Management Holding GmbH, Tempo-Team Engineering GmbH, Tempo-Team
        Personaldienstleistungen GmbH sowie Tempo-Team Managed Service Provider GmbH)
        <br />
        genutzt werden, was auch die Weitergabe meiner Daten an die jeweiligen Kunden der Randstad-Gruppe umfasst.
        Ich bin einverstanden, dass meine Daten auch schon vor Abschluss meines Bewerbungsverfahrens in
        Marketingaktionen von Randstad verwendet werden können. Meine Daten dürfen zudem über das Ende des konkreten
        Bewerbungsverfahrens hinaus gespeichert werden, um mich später für andere Stellen zu kontaktieren.
      </p>
      <p>
        2.&nbsp; &nbsp; Meine Einwilligung gilt zu meinem eigenen Schutz nur für eine Dauer von maximal zwölf
        Monaten. Danach wird durch Randstad keine Verarbeitung meiner Daten auf Basis dieser Einwilligung mehr
        vorgenommen.
      </p>
      <p>
        3.&nbsp; &nbsp; Ich habe das Recht, diese Einwilligung jederzeit, also auch vor Ablauf der zwölf Monate,
        ohne Angabe einer Begründung für die Zukunft zu widerrufen. Durch den Widerruf wird die Rechtmäßigkeit der
        aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
      </p>
      <p>
        4.&nbsp; &nbsp; Ich habe das Recht, dieser Einwilligungserklärung nicht zuzustimmen. In diesem Fall kann ich
        meine Bewerbung nicht online abgeben, sondern muss diese per Post übersenden.
      </p>
      <p>
        Mir ist bewusst, dass meine personenbezogenen Daten nicht nur nach der vorstehenden Einwilligung für die
        vorgenannten Maßnahmen verarbeitet werden, sondern auch aufgrund gesetzlicher Grundlagen, einschließlich des
        berechtigten Interesses von Randstad. Weitere Informationen finde ich dazu, auch zur verantwortlichen
        Stelle, in den <a href="/datenschutzbestimmungen">Datenschutzhinweisen</a> von Randstad.
      </p>
    </Modal>
  );
};

export default PrivacyPolicyModal;
