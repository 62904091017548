import React, {useState, useEffect, useContext, useRef} from 'react';
import {injectIntl} from 'react-intl';
import Icon from '@Components/Icon';
import FormContext from 'formContext';
import axios from 'axios';

function JobAlertItem({intl, alert, index}) {
  const {
    setFormName,
    setFormData,
    setModalOpen,
    setEditedItemKey,
    setErrors,
  } = useContext(FormContext);

  const [loading, setLoaded] = useState(true);
  const [jobsData, setJobsData] = useState('');
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    let searchParams = {
      keyword: alert.searchParameters.keyword,
      jobDistance: alert.searchParameters.maxdistance,
    }
    if (alert.searchParameters.zipcode) {
      searchParams = {
        ...searchParams,
        ...{zipcode: alert.searchParameters.zipcode}
      }
    }
    if (alert.searchParameters.city && alert.searchParameters.city !== 'remote') {
      searchParams = {
        ...searchParams,
        ...{city: alert.searchParameters.city}
      }
    }
    if (alert.searchParameters.state) {
      searchParams = {
        ...searchParams,
        ...{region: alert.searchParameters.state}
      }
    }
    if (alert.searchParameters.city === 'remote') {
      searchParams = {
        ...searchParams,
        ...{isRemote: true}
      }
    }
    axios.post(`${process.env.REACT_APP_API_PREFIX}/get-callback`, {
      callback: '@Callbacks/getSearchResults',
      currentRoute: {},
      data: searchParams
    }).then(response => {
      if (mounted.current === true) {
        setJobsData(response.data);
        setLoaded(false);
      }
    });

  }, [alert]);

  const count = alert.searchParameters ? Object.values(Object.fromEntries(Object.entries(alert.searchParameters).filter(([_, v]) => v != null))).length : 0;

  // Opens edit form modal.
  const handleEditJobAlert = () => {
    const item = alert;
    let data = {};
    data['index'] = index;
    if (item['frequency']) {
      data['frequency'] = item['frequency'];
    }
    if (item['searchParameters']['zipcode']) {
      data['jobAlertLocation'] = item['searchParameters']['zipcode'];
    }
    if (item['searchParameters']['keyword']) {
      data['keyword'] = item['searchParameters']['keyword'];
    }
    if (item['searchParameters']['maxdistance']) {
      data['jobDistance'] = item['searchParameters']['maxdistance'].toString();
    }
    if (item['searchParameters']['state']) {
      data['jobAlertLocation'] = item['searchParameters']['state'];
    }
    // Handle 'remote' location.
    if (item['searchParameters']['city'] === 'remote') {
      data['jobAlertLocation'] = item['searchParameters']['city'];
    }
    if (item['searchParameters']['state'] && item['searchParameters']['city']) {
      data['jobAlertLocation'] = `${item['searchParameters']['city']}, ${item['searchParameters']['state']}`;
    }

    setFormName('job-alert');
    setErrors();
    setEditedItemKey(index);
    setFormData([data]);
    setModalOpen(true);
  }

  const renderEditButton = () => {
    const disableEditButton = process.env.REACT_APP_DISABLE_EDIT_BUTTON_FROM_ALERTS;
    if (disableEditButton && disableEditButton === 'true') {
      return '';
    }
    return (
      <button className="button--clean" onClick={handleEditJobAlert}>
        <Icon type="edit" className="icon icon--inline icon--hover"/>
        <span className="hidden--visually"/>
      </button>
    );
  }

  // Opens ConfirmationModal component from dashboard page.
  const openDeleteConfirmationModal = () => {
    if (index === null) {
      setFormData([{index: null}]);
      setModalOpen(true);
      return;
    }
    let data = {};
    data['alertName'] = alert['alertName'] || '';
    data['index'] = index;

    setFormData([data]);
    setModalOpen(true);
  }

  return (
    <li className="list-item divider" key={alert.id} id={`job-alert-${alert.id}`}>
      <div className="list-item__info">
        <div className="list-item__title list-item__title--split">
          <h3 className="list-item__header list-item__header--badge ">
            <a href={jobsData.searchUrl} className="list-item__link">
              {alert.searchParameters.keyword}
            </a>
          </h3>
          <div className="list-item__controls">
            {renderEditButton()}
            <button className="button--clean" onClick={openDeleteConfirmationModal}>
              <Icon type="trash" className="icon icon--inline icon--hover"/>
              <span className="hidden--visually"/>
            </button>
          </div>
        </div>
        <ul className="list-item__meta">
          <li>{intl.formatMessage({id: 'JobAlerts.JobAlert.FrequencyPerEmail.Text'}, {frequency: alert.frequency.toLowerCase()})}</li>
          {
            count > 0 ? <li>{intl.formatMessage({id: 'JobAlerts.JobAlert.SpecifiedSettings.Text'}, {count: count})}</li>
            : <li>{intl.formatMessage({id: 'JobAlerts.JobAlert.NoFilterSet.Text'})}</li>
          }
        </ul>
      </div>
      <a href={jobsData.searchUrl} className={`button button--m ${loading ? 'button--preloader' : ''}`}>
        <span className="dots" />
        {!loading ? intl.formatMessage({id: 'JobAlerts.JobAlert.ViewResults.Text'}, {total: jobsData.total}) : ''}
      </a>
    </li>
  );
}

export default injectIntl(JobAlertItem);
