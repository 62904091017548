import React from 'react';
import {injectIntl} from 'react-intl';
import Icon from '@Components/Icon';

const NoticeInPage = ({intl, type, text}) => {
  let classes; let iconName;

  switch (type) {
    case 'positive':
      classes = 'notice-in-page notice-in-page--positive';
      iconName = 'check';
      break;
    case 'informative':
      classes = 'notice-in-page notice-in-page--informative';
      iconName = 'info';
      break;
    case 'negative':
      classes = 'notice-in-page notice-in-page--negative';
      iconName = 'warning';
      break;
    default:
      classes = 'notice-in-page notice-in-page--informative';
      iconName = 'info';
      break;
  }

  return (
    <div className={classes}>
      <Icon type={iconName} className="icon icon--inline"/>
      <span className='notice-in-page__body-copy' dangerouslySetInnerHTML={{__html: intl.formatMessage({id: text})}}/>
    </div>
  );
};

export default injectIntl(NoticeInPage);
