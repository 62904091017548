import React from 'react';
import {injectIntl} from "react-intl";

function Spacer({intl, settings}) {
  const {
    hidden = false,
    width = 'calc((38 / 43) * 100%)',
    margin = '50px 0',
    border = 'none',
    height = '1px',
    background = '#d7d7d7',
    textBackground = '#fff',
    text = false
  } = settings || {};
  if (hidden) {
    return '';
  }

  if (text) {
    return (
      <div className="spacer-with-text" style={{width: width, margin: margin}}>
        <span className="spacer-with-text__text" style={{background: textBackground}}>{intl.formatMessage({id: text})}</span>
        <hr style={{
          width: "100%",
          border: border,
          height: height,
          backgroundColor: background,
        }}/>
      </div>
    )
  }
  return (
    <hr style={{
      width: width,
      margin: margin,
      border: border,
      height: height,
      backgroundColor: background,
    }}/>
  )
}

export default injectIntl(Spacer);
