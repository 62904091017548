import React from "react";
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function ExperienceItem({children, title, handleEditItem, handleDeleteItem, label, index = 0, divider = 'false'}) {
  return (
    <li className={`list-item list-item--l ${String(divider) === 'true' ? "divider" : ""}  border-radius--l`}>
      <div className="list-item__info">
        <div className="list-item__title list-item__title--split">
          <h3 className="list-item__header">{title}</h3>
          <div className="list-item__controls">
            {handleEditItem && (
              <button className="button--clean" onClick={e => handleEditItem(index)}>
                <Icon type="edit" className="icon icon--inline"/>
                <span className="hidden--visually">{label}</span>
              </button>
            )}
            {handleDeleteItem && (
              <button className="button--clean" onClick={e => handleDeleteItem(index)}>
                <Icon type="trash" className="icon icon--inline icon--hover"/>
                <span className="hidden--visually"/>
              </button>
            )}
          </div>
        </div>
        {children}
      </div>
    </li>
  );
}

export default injectIntl(ExperienceItem);
