import React, {useEffect, useState} from 'react';
import {injectIntl} from "react-intl";
import axios from "axios";
import { useCookies } from "react-cookie";
import {Sha256} from '@aws-crypto/sha256-browser';
import {toHex} from '@aws-sdk/util-hex-encoding';
import Modal from '@Components/Modal';
import GetRouteByName from 'getRouteByName';
import useResumeBuilderHelper from 'resumeBuilderHelper';
import {pushResumeBuilderEvent} from 'dataLayerHelper';
import Logger from '@ffw/logger-lib';

function SaveResumeBlock({intl, loggedIn, img}) {
  const loginRoute = GetRouteByName('login').url;
  const signUpRoute = GetRouteByName('register').url;
  const buttonClasses = 'button button--m';
  const text = loggedIn ?
    intl.formatMessage({id: 'ResumeBuilder.Page.ConnectResume.LoggedIn.Text'}) : intl.formatMessage({id: 'ResumeBuilder.Page.ConnectResume.LoggedOut.Text'});
  const [resumeSaved, setResumeSaved] = useState(false);
  const [resumeExists, setResumeExists] = useState(false);
  const [showSaveResumeBlock, setShowSaveResumeBlock] = useState(true);
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [hashedEmail, setHashedEmail] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies([])
  const {getResumeData} = useResumeBuilderHelper();

  useEffect(() => {
    const resumeData = getResumeData();
    if (Object.keys(resumeData).length && resumeData.contact_info) {
      const hash = new Sha256();
      hash.update(resumeData.contact_info.email);
      hash.digest().then((result) => {
        setHashedEmail(toHex(result));
      });
    }
  }, []);

  const uploadResumeToProfile = () => {
    const resumeData = getResumeData();
    axios.post(`${process.env.REACT_APP_API_PREFIX}/resume-builder-upload`, {
      resumeData: resumeData,
      newProfile: sessionStorage.getItem('ResumeBuildNewProfile')
    }).then(response => {
      if (response.status >= 200 && response.status < 300) {
        setShowSaveResumeBlock(false);
        setResumeSaved(true);
        setModalDisplayed(true);
        setResumeExists(true);
        sessionStorage.removeItem('ResumeBuildNewProfile');
        pushResumeBuilderEvent('success', 'connectYourResume', hashedEmail);
      }
    })
      .catch(error => {
        Logger.error('Error getting resume file: ' + error.message, 'components/SaveResumeBlock/us');
      });
  };

  useEffect(() => {
    if (loggedIn) {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user/cv`).then(response => {
        if (response.data && response.data.filename) {
          setResumeExists(true);
          if (!cookies.ResumeBuildSave) {
            setCookie('ResumeBuildSave', 1, {sameSite: 'lax', secure: !process.env.REACT_APP_ISDEV});
            setModalDisplayed(true);
            pushResumeBuilderEvent('existingResumePrompt', 'connectYourResume', hashedEmail);
          }
        }
        else {
          setCookie('ResumeBuildSave', 1, {sameSite: 'lax', secure: !process.env.REACT_APP_ISDEV});
          uploadResumeToProfile();
        }
      });
    }
  }, []);

  const onSaveResume = (e, submit = false) => {
    e.preventDefault();
    if (!submit) {
      setModalDisplayed(true);
      pushResumeBuilderEvent('letsDoIt', 'connectYourResume', hashedEmail);
      pushResumeBuilderEvent('existingResumePrompt', 'connectYourResume', hashedEmail);
    }
    else {
      pushResumeBuilderEvent('existingResumeReplaceFile', 'connectYourResume', hashedEmail);
      uploadResumeToProfile();
    }
  }

  const onClose = (e, existingResume) => {
    setResumeSaved(false);
    setModalDisplayed(false);
    if (existingResume) {
      pushResumeBuilderEvent('existingResumeKeepOldFile', 'connectYourResume', hashedEmail);
    }
  }

  const modalRender = () => {
    let modalChildren,
      title = '';
    if (resumeExists && !resumeSaved) {
      title = intl.formatMessage({id: "ResumeBuilder.ResumeExists.Modal.Title"});
      modalChildren = (
        <div className="replace-resume-content">
          <p>{intl.formatMessage({id: "ResumeBuilder.ResumeExists.Modal.Text"})}</p>
          <button className="button button--filled button--m" onClick={(e) => onSaveResume(e, true)}>
            {intl.formatMessage({id: "ResumeBuilder.ResumeExists.Modal.YesButton.Text"})}
          </button>
          <button className="button--link replace-resume-content__replace-link" onClick={(e) => onClose(e, true)}>
            {intl.formatMessage({id: "ResumeBuilder.ResumeExists.Modal.NoButton.Text"})}
          </button>
        </div>
      );
    }
    else {
      title = intl.formatMessage({id: "ResumeBuilder.SaveConfirmation.Modal.Title"});
      modalChildren = (
        <div>
          <p>{intl.formatMessage({id: "ResumeBuilder.SaveConfirmation.Modal.Text"})}</p>

          <button className="button button--filled button--m" onClick={(e) => onClose(e)}>
            {intl.formatMessage({id: "Register.Form.Button.Step1.Text"})}
          </button>
        </div>
      );
    }

    if (modalDisplayed) {
      return (
        <Modal title={title} children={modalChildren} onClose={(e) => onClose(e)} />
      )
    }

    return ''
  };

  const buttons = () => {
    const onRedirect = (e, url) => {
      e.preventDefault();
      setCookie('ResumeBuild', 1, {path: url, sameSite: 'lax', secure: !process.env.REACT_APP_ISDEV});
      window.location = url;
    }

    if (loggedIn) {
      return (
        <div className="flex form-group--buttons">
          <a className={buttonClasses + ' button--filled'} href="#" onClick={(e) => onSaveResume(e)}>
            {intl.formatMessage({id: "ResumeBuilder.Page.AttachResume.Button"})}
          </a>
        </div>
      )
    }

    return (
      <div className="flex form-group--buttons">
        <a href={signUpRoute} className={buttonClasses + ' button--filled'} onClick={(e) => onRedirect(e, signUpRoute)}>
          {intl.formatMessage({id: "ResumeBuilder.Page.SignUp.Button"})}
        </a>
        <a href={loginRoute} className={buttonClasses} onClick={(e) => onRedirect(e, loginRoute)}>
          {intl.formatMessage({id: "ResumeBuilder.Page.SignIn.Button"})}
        </a>
      </div>
    )
  };

  return (
    <div className="banner split-view--photo bg-brand--off-white banner--attach-resume">
      {showSaveResumeBlock ?
        <div className="banner__wrapper wrapper wrapper--remove-top-p">
          <div className="banner__media media-block media-block--center media-block--half-width">
            <img src={img} alt="save resume image"/>
          </div>
          <div className="banner__content">
            <h2 className="content-block__title">
              {intl.formatMessage({id: 'ResumeBuilder.Page.ConnectResume.Title'})}
            </h2>
            <div className="content-block__description body-copy">
              <p>
                {text}
              </p>
            </div>
            {buttons()}
          </div>
        </div>:
        ''
      }
      {modalRender()}
    </div>
  )
}

export default injectIntl(SaveResumeBlock);
