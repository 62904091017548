const Joi = require('@hapi/joi');

module.exports = (intl) => {
  return {
    schema: Joi.object().keys({
      email: Joi.string()
          .required()
          .lowercase()
          .pattern(/^\w+([-+_.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
          .messages({
            'any.required': intl.formatMessage({id: 'Schema.Login.Email.Required'}),
            'string.empty': intl.formatMessage({id: 'Schema.Login.Email.Required'}),
            'string.pattern.base': intl.formatMessage({id: 'Schema.Signup.Email.EmailString'}),
          }),
      password: Joi.string()
          .required()
          .messages({
            'any.required': intl.formatMessage({id: 'Schema.Login.Password.Required'}),
            'string.empty': intl.formatMessage({id: 'Schema.Login.Password.Required'}),
          })
          .strip(),
      remember_me: Joi.any(),
      captchaToken: Joi.any().strip(),
    }),
  };
};
