import React, {useEffect, useRef} from "react";
import Icon from "@Components/Icon";
import ModalJS from '@Components/ModalJS'

const Modal = ({title, children, onClose, footer, footerDivider = true}) => {
  const modalRef = useRef();

  useEffect(() => {
    const ModalJSInit = new ModalJS(modalRef.current);
    ModalJSInit.openModal(false);

    // Handle X button event.
    modalRef.current.addEventListener('ModalClosed', () => {
      onClose();
    });

    return () => {
      ModalJSInit.closeModal(false);
    };


  }, []);

  return (
    <>
      <div className="modal" ref={modalRef}>
        <div className="modal__dialog bg-variant-brand-tertiary" role="dialog" aria-modal="true" aria-labelledby="#" data-rs-modal-dialog="">
          <div className="modal__header" data-rs-modal-header="">
            <h3 className="modal__title">{title}</h3>
            <button className="button--icon-only modal__close" data-rs-modal-close-trigger="">
              <Icon type='close' className='icon icon--inline hidden--from-l fill-brand--blue icon--alternative'/>
              <Icon type='close-30' className='icon icon--l icon--inline hidden--until-l icon--alternative'/>
            </button>
          </div>
          <div className="modal__main modal__main--overflow" data-rs-modal-main="">{children}</div>
          {footer &&
          <div className={`modal__footer ${footerDivider ? 'divider divider--top' : ''}`} data-rs-modal-footer="">{footer}</div>}
        </div>
      </div>
    </>
  );
};

export default Modal;
