import React from 'react';
import {injectIntl} from 'react-intl';
import Joi from '@hapi/joi';
import Logger from '@ffw/logger-lib';

const EmailFieldValidated = ({intl, settings, onChange, rest}) => {
  const {
    label,
    labelHidden,
    name,
    placeholder,
    state,
    errors,
    setErrors,
    required = true,
    disabled = false,
    hidden = false,
    description = false,
    divClasses = '',
  } = settings || {};

  if (hidden) {
    return '';
  }

  const id = `field--input--${name}`;

  const dataValidator = (values, schema, context) => {
    const validation = schema.validate(values, {abortEarly: false, context: context});

    if (validation.error && validation.error.details.length) {
      const {details} = validation.error;

      return joiErrorParser(details, schema.errorOverrides);
    }

    return false;
  };

  const joiErrorParser = (errors, overrides) => {
    const parsedErrors = {};
    for (const id in errors) {
      const error = errors[id];
      if (overrides && overrides[error.context.key] && overrides[error.context.key][error.type]) {
        error.message = overrides[error.context.key][error.type];
      }
      if (process.env.REACT_APP_ISDEV) {
        Logger.error(error, 'components/FormElements/EmailFieldValidated/us');
      }

      // Set error to parent element when nested validations are used.
      parsedErrors[error.path[0]] = error.message;
    }

    return parsedErrors;
  };

  const validationSchema = Joi.object().keys({
    email: Joi.string()
        .pattern(/^\w+([-+_.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
        .lowercase()
        .required(),
  });

  validationSchema.errorOverrides = {
    email: {
      'any.required': intl.formatMessage({id: 'Schema.Login.Email.Required'}),
      'string.pattern.base': intl.formatMessage({id: 'Schema.Signup.Email.EmailString'}),
      'string.empty': intl.formatMessage({id: 'Schema.Login.Email.Required'}),
    },
  };

  const handleChange = (event) => {
    event.persist();
    if (required) {
      // Validate email on front end.
      const formErrors = dataValidator(
          {
            [name]: event.target.value,
          },
          validationSchema,
      );
      if (formErrors) {
        setErrors((prev) => {
          if (!prev) {
            return formErrors;
          }
          return {
            ...prev,
            ...formErrors,
          };
        });
      } else {
        setErrors((prev) => {
          if (prev && prev[name]) {
            delete prev[name];
          }

          return prev;
        });
      }
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  return (
    <div className={`form-group form-group--${name} ${errors && errors[name] ? 'form-group--error' : ''} ${divClasses ? `${divClasses}` : ''}`} >
      {label &&
        <label className={`form-group__label ${labelHidden ? 'hidden--visually' : ''}`} htmlFor={id}>
          {intl.formatMessage({id: label})}
        </label>
      }
      <input type="text"
        name={name}
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={handleChange}
        value={state && state[name] ? state[name] : ''}
        {...rest}
      />
      {description && <div className="form-group__message">{description}</div>}
      {errors && errors[name] && <div className="form-group__feedback">{ errors[name] }</div>}
    </div>
  );
};

export default injectIntl(EmailFieldValidated);
