import React, {useContext, useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import Form from 'form';
import FormContext from 'formContext';
import ResumeBuilderCard from '@Components/ResumeBuilderCard';
import useDateHelper from 'dateHelper';
import useResumeBuilderHelper from 'resumeBuilderHelper';

function ResumeBuilderStepForm(
  {
    intl,
    staticContext,
    step,
    form,
    index,
    handleEdit,
    handleSave,
    handleAdd,
    stepData = false,
    editMode = false,
    addCancelButton = false,
    addMode,
    updateHeight,
    cancelHandler
  }) {
  const {formData, setFormData, getFormSchema, currentStep, handleErrors} = useContext(FormContext);
  const [editing, setEditing] = useState(editMode);
  const stepItemData = typeof stepData[index] !== 'undefined' ? stepData[index] : false;
  const { convertToValidDate, createNewDate } = useDateHelper();
  const {setResumeData, getResumeData, deleteResumeDataItem, formatLocationData} = useResumeBuilderHelper();

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    const skipField = event.target.getAttribute('data-skip-empty-field');
    if (event.target.type === 'checkbox') {
      let emptyValue = event.target.getAttribute('data-empty-field-value');
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue :
        emptyValue ? emptyValue : null;
    }

    setFormData((prev) => {
      if (!prev[step] || !prev[step][index]) {
        prev[step] = {
          [index]: {
            [currentStep]: {},
          },
        };
      }
      prev[step][index][currentStep][name] = value;

      // In some cases we do not want empty values, i.e. some checkboxes.
      if (!value && skipField) {
        delete prev[step][index][currentStep][name];
      }
      return {
        ...prev
      }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setResumeData(form, step, index).then(resumeData => {
      if (resumeData && resumeData[step]) {
        delete formData[step][index];
        if (!formData[step].length) {
          delete formData[step];
        }
        setFormData({...formData});
        setEditing(false);
        handleSave();
        handleErrors([]);
      }
    }).catch(error => {
      handleErrors(error.response.data);
      if (updateHeight instanceof Function) {
        updateHeight();
      }
    });
  };

  const handleCancel = event => {
    event.preventDefault();
    setEditing(false);
    handleEdit(false);
    if (formData[step]) {
      delete formData[step][index];
    }
    if (formData[step] && !formData[step].length) {
      delete formData[step];
    }
    if (cancelHandler instanceof Function) {
      cancelHandler()
    }
  };

  const onEditHandler = event => {
    event.preventDefault();
    const resumeData = getResumeData();
    if (resumeData && resumeData[step] && resumeData[step][index]) {
      setFormData({
        ...formData,
        [step]: {
          [index]: {
            [currentStep]: {...resumeData[step][index]},
          },
        },
      });
    }
    setEditing(true);
    handleEdit(index);
  };

  const onAddHandler = event => {
    event.preventDefault();
    handleAdd();
  }

  const onDeleteHandler = event => {
    deleteResumeDataItem(step, index);
    handleSave();
  }

  const handleDate = (startDate, endDate, currentDate) => {
    let dateRange = '';
    let startDateStr = '';
    let endDateStr = '';
    if (typeof startDate === 'undefined' || !startDate.length) {
      return dateRange;
    }
    startDate = convertToValidDate(startDate);

    const startDateObject = createNewDate(startDate);
    startDateStr = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(startDateObject) + ' ' + startDateObject.getFullYear();
    if (endDate) {
      endDate = convertToValidDate(endDate);

      const endDateObject = createNewDate(endDate);
      endDateStr = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(endDateObject) + ' ' + endDateObject.getFullYear();
    }
    if (currentDate === true) {
      dateRange = startDateStr + ' - ' + intl.formatMessage({id: 'ResumeBuilder.Form.CurrentDate.Text'});
    }
    else {
      dateRange = startDateStr + ' - ' + endDateStr;
    }
    return dateRange.toLowerCase();
  }

  let formContent = '';
  if (Object.keys(stepItemData).length && !editing) {
    let savedContent = ''
    switch (step) {
      case 'employment_history':
        const employmentData = [
          stepItemData.job_title,
          formatLocationData(stepItemData.employment_history_location.toLowerCase(), false, stepItemData.locationData),
          stepItemData.description,
          handleDate(stepItemData.start_date, stepItemData.end_date, stepItemData.currently_work_here)
        ]

        savedContent = (
          <ResumeBuilderCard title={stepItemData.employer_name} rows={employmentData} handleEdit={onEditHandler} handleDelete={onDeleteHandler}/>
        );
        break;
      case 'education':
        let educationLevel = '';
        if (typeof stepItemData.education_level !== 'undefined' && stepItemData.education_level.length) {
          const schema = getFormSchema(form);
          const levelField = schema[0].data[0].components.find(field => field.settings.name === 'education_level');
          const selectedLevel = levelField.settings.options.find(option => option.value === stepItemData.education_level);
          educationLevel = (
            <div>{intl.formatMessage({id: selectedLevel.label})}</div>
          );
        }
        const educationData = [
          educationLevel,
          formatLocationData(stepItemData.education_location.toLowerCase(), false, stepItemData.locationData),
          handleDate(stepItemData.education_start_date, stepItemData.education_end_date, stepItemData.currently_go_here)
        ]

        savedContent = (
          <ResumeBuilderCard title={stepItemData.school_name} rows={educationData} handleEdit={onEditHandler} handleDelete={onDeleteHandler}/>
        );
        break;
      case 'certifications':
        let expirationDate = '';
        if (typeof stepItemData.certification_expiration_date !== 'undefined' && stepItemData.certification_expiration_date.length) {
          const date = convertToValidDate(stepItemData.certification_expiration_date);
          const expirationDateObject = createNewDate(date);
          const expirationDateStr = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(expirationDateObject) + ' ' + expirationDateObject.getDate() + ', ' + expirationDateObject.getFullYear();
          expirationDate = (
            <div>
              {intl.formatMessage({id: 'ResumeBuilder.Form.CertificationExpireText.Label'}, {date: `${expirationDateStr.toLowerCase()}`})}
            </div>
          );
        }

        savedContent = (
          <ResumeBuilderCard title={stepItemData.certification} rows={[expirationDate]} handleEdit={onEditHandler} handleDelete={onDeleteHandler}/>
        );
        break;
      case 'skills':
        savedContent = (
          <ResumeBuilderCard rows={stepItemData} handleEdit={!addMode ? onAddHandler : false} handleSkillDelete={addMode} handleSave={handleSave} />
        )
        break;
    }
    formContent = (
      <div>
        {savedContent}
      </div>
    );
  }
  else {
    const formState = formData[step] && formData[step][index] && formData[step][index][currentStep] ? formData[step][index][currentStep] : [];
    formContent = <Form name={form} handleChange={handleChange} itemKey={index} state={formState} handleSubmit={handleSubmit} handleCancel={handleCancel} addCancelButton={editing || addCancelButton} />;
  }

  return (
    <div className='resume-builder-steps-wrapper'>
      {formContent}
    </div>
  );
}

export default injectIntl(ResumeBuilderStepForm);
