import React from "react";
import Icon from "@Components/Icon";

function Tags({tags = [], handleRemove}) {

  const renderTags = () => {
    const renderable = [];

    tags.forEach((tag, index) => {
      renderable.push(
        <div className="tag tag--remove bg-brand--light-blue" data-rs-tags="true" key={`tags-${index}`}>
          <span className="tag__text">{tag}</span>
          <div className="tag__close" data-rs-tags-remove="true" onClick={() => handleRemove(index)}>
            <Icon type="close" className="icon icon--inline" />
          </div>
        </div>
      );
    });

    return (
      <div className="form-group element-group">
        {renderable}
      </div>
    );
  };

  return (
    <>

      {renderTags()}
    </>
  );
}

export default Tags;
