import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import Icon from '@Components/Icon';
import JobAlertItem from '@Components/JobAlertItem';

function JobAlertList({intl, jobAlerts, perPage = 5}) {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(Math.ceil(jobAlerts.subscriptions.length / perPage));

  useEffect(() => {
    const pageNr = Math.ceil(jobAlerts.subscriptions.length / perPage);

    if (pageNr !== totalPages) {
      setTotalPages(() => {
        // If number of pages is less go to previous page.
        if (pageNr < page + 1) {
          changePage(page - 1);
        }

        return pageNr;
      });
    }
  }, [jobAlerts.subscriptions.length, perPage]);

  const changePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const pager = () => {
    const items = [];

    if (page > 0) {
      items.push(
        <li className="pagination__item" key="job-alerts-page-prev">
          <button className="pagination__control button--clean" onClick={() => changePage(page - 1)}>
            <Icon type="arrow-left" className="icon icon--inline"/>
            <span className="hidden--visually">{intl.formatMessage({id: 'Utils.Previous'})}</span>
          </button>
        </li>
      );
    }

    for (let i = 0; i < totalPages; i++) {
      items.push(
        <li className="pagination__item" key={`job-alerts-page-${i}`}>
          {page === i && <span>{i + 1}</span>}
          {page !== i && <button className="button--clean" onClick={() => changePage(i)}>{i + 1}</button>}
        </li>
      );
    }

    if (page < totalPages - 1) {
      items.push(
        <li className="pagination__item" key="job-alerts-page-next">
          <button className="pagination__control button--clean" onClick={() => changePage(page + 1)}>
            <Icon type="arrow-right" className="icon icon--inline"/>
            <span className="hidden--visually">{intl.formatMessage({id: 'Utils.Next'})}</span>
          </button>
        </li>
      );
    }

    return items;
  };

  const renderJobAlerts = () => {
    if (!jobAlerts || !jobAlerts.subscriptions || jobAlerts.subscriptions.length === 0) {
      return null;
    }
    const pageItems = jobAlerts.subscriptions.slice(page * perPage, page * perPage + perPage);
    const alerts = [];
    pageItems.forEach((alert, index) => {
      const overallIndex = index + (page * perPage);
      alerts.push(<JobAlertItem key={alert.id} alert={alert} index={overallIndex}/>)
    });

    return(<ul className="list-items">{alerts}</ul>);
  }

  return (
    <>
      {renderJobAlerts()}
      {totalPages > 1 && (
        <nav className="pagination" data-rs-pagination="">
          <ul className="pagination__list" data-rs-pagination-list="">
            {pager()}
          </ul>
        </nav>
      )}
    </>
  )
}

export default injectIntl(JobAlertList);
