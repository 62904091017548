import React, {Fragment, useContext, useEffect} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import Spacer from "@Components/Spacer";
import TextWithLink from "@Components/TextWithLink";
import TextWithHtml from "@Components/TextWithHtml";
import InputField from "@FormElements/InputField";
import TextArea from "@FormElements/TextArea";
import DatePicker from "@FormElements/DatePicker";
import PhoneField from "@FormElements/PhoneField";
import JobAlertActiveFilters from "@Components/JobAlertActiveFilters";
import PasswordField from "@FormElements/PasswordField";
import Checkbox from "@FormElements/Checkbox";
import Button from "@FormElements/Button";
import UploadField from "@FormElements/UploadField";
import RadioButtons from "@FormElements/RadioButtons";
import SelectField from "@FormElements/SelectField";
import AutoSuggestField from "@FormElements/AutoSuggestField";
import AutoSuggestWithTags from "@FormElements/AutoSuggestWithTags";
import EmailFieldValidated from '@FormElements/EmailFieldValidated';
import SpecialismSelectFields from "@Components/SpecialismSelectFields";
import RegionSelectField from "@Components/RegionSelectField";
import CountrySelectField from "@Components/CountrySelectField";
import SkillRadioButtons from "@Components/SkillRadioButtons";
import IndustryPreferences from "@Components/IndustryPreferences";
import WorkplaceAutosuggest from "@Components/WorkplaceAutosuggest";
import Error from "@Components/Error";
import Icon from "@Components/Icon";
import ResetPasswordEmailText from "@Components/ResetPasswordEmailText";
import CountryAutoSuggest from "@Components/CountryAutoSuggest";
import UserImageBlock from "@Components/UserImageBlock";
import SalarySelectField from "@Components/SalarySelectField";
import NoticePeriodField from "@Components/NoticePeriodField";
import StackableCheckboxes from "@FormElements/StackableCheckboxes";
import SpecialismAutoSuggestField from "@Components/SpecialismAutoSuggestField";
import PermSalarySelectField from "@Components/PermSalarySelectField";
import TempSalarySelectField from "@Components/TempSalarySelectField";
import JobTitleAutoSuggestField from "@Components/JobTitleAutoSuggestField";
import StateAutosuggestField from "@Components/StateAutosuggestField";
import BranchSelectField from "@Components/BranchSelectField";
import SocialLogin from "@Components/SocialLogin";
import SocialLoginReset from '@Components/SocialLoginReset';
import Logger from '@ffw/logger-lib';
import Captcha from "@FormElements/Captcha";

const supportedComponents = {
  InputField,
  PasswordField,
  Checkbox,
  Button,
  Spacer,
  TextWithLink,
  UploadField,
  RadioButtons,
  SelectField,
  SpecialismSelectFields,
  RegionSelectField,
  CountrySelectField,
  SkillRadioButtons,
  TextArea,
  DatePicker,
  AutoSuggestField,
  AutoSuggestWithTags,
  IndustryPreferences,
  WorkplaceAutosuggest,
  JobAlertActiveFilters,
  ResetPasswordEmailText,
  CountryAutoSuggest,
  UserImageBlock,
  SalarySelectField,
  NoticePeriodField,
  StackableCheckboxes,
  TextWithHtml,
  SpecialismAutoSuggestField,
  PermSalarySelectField,
  TempSalarySelectField,
  JobTitleAutoSuggestField,
  StateAutosuggestField,
  BranchSelectField,
  SocialLogin,
  SocialLoginReset,
  EmailFieldValidated,
  PhoneField,
  Captcha,
};

function Form({ name, itemKey, intl, handleChange, state, handleSubmit, handleCancel, addCancelButton = false, hideGeneralErrors = false}) {
  const {
    setFormData,
    getFormSchema,
    errors,
    setErrors,
    currentStep,
    setCurrentStep,
    setFormName,
    renderStep,
    loading
  } = useContext(FormContext);
  const schema = getFormSchema(name);
  let formSchema;
  let isModal;
  const sections = [];

  useEffect(() => {
    setFormName(name);
    setCurrentStep(0);
  }, [name]);

  if (schema[currentStep] !== undefined) {
    formSchema = schema[currentStep].data;
    isModal = schema[currentStep].isModal;
  }
  else {
    formSchema = schema[0].data;
    isModal = schema[0].isModal;
  }

  const renderComponents = () => {
    formSchema.forEach((section, sectionIndex) => {
      const sectionParts = [];
      const elementItems = [];
      const components = section.components;
      // Render the section title if available.
      if (section.hasOwnProperty('title') && section.title.length > 0) {
        sectionParts.push(
          <div className="block__header" key={`section-title-${sectionIndex}`}>
            <h2 className="block__title">{intl.formatMessage({id: section.title})}</h2>
          </div>
        );
      }

      const cancelButton = (
        <button className="button button--m button--full-width" onClick={handleCancel}>
          {intl.formatMessage({id: 'ResumeBuilder.Form.CancelButton.Text'})}
        </button>
      );
      // Iterate through all components in the section and push them to elementItems array.
      components.forEach((component, componentIndex) => {
        if (!supportedComponents.hasOwnProperty(component.component)) {
          Logger.warn(`The component "${component.component}" is not supported, but used in forms.config.json.`, 'hooks/form/us');
          return;
        }
        const Component = supportedComponents[component.component];
        const settings = component.settings || {};
        settings.state = state;
        settings.errors = errors;
        settings.setErrors = setErrors;
        settings.loading = loading;
        settings.itemKey = itemKey || 0;
        // Handle effects if any,
        if (component.effects) {
          component.effects.forEach(effect => {
            handleEffect(effect, settings, state);
          });
        }

        if (sectionIndex === 0 && componentIndex === 0) {
          // Check if we should render the general error message.
          if (errors && errors.hasOwnProperty('generalError') && !hideGeneralErrors) {
            elementItems.push(
              <Error message={errors.generalError} key={`error-${new Date().getTime()}`} />
            );
          }
        }

        if (component.component === 'Button' && addCancelButton) {
          component.settings.withWrapperDiv = false;
          elementItems.push(
            <div className="l:flex form-group--buttons" key={`section-${sectionIndex}-component-${componentIndex}`}>
              <Component settings={settings} onChange={handleChange}/>
              {cancelButton}
            </div>
          );
        }
        else if (component.component === 'Button' && !addCancelButton) {
          component.settings.withWrapperDiv = true;
          elementItems.push(
            <Component settings={settings} key={`section-${sectionIndex}-component-${componentIndex}`} onChange={handleChange}/>
          );
        }
        else if (component.component === 'PhoneField' && name.startsWith('resume-builder-')) {
          elementItems.push(
            <Component settings={settings} key={`section-${sectionIndex}-component-${componentIndex}`} onChange={handleChange} formName={name} itemKey={itemKey} />
          );
        }
        else {
          elementItems.push(
            <Component settings={settings} key={`section-${sectionIndex}-component-${componentIndex}`} onChange={handleChange}/>
          );
        }
      });

      if (isModal === true) {
        sectionParts.push(
          <Fragment key={`section-${sectionIndex}-components`}>
            {elementItems}
          </Fragment>
        );
        sections.push(
          <Fragment key={`section-${sectionIndex}-components`}>
            {renderPreviousStep()}
            {sectionParts}
          </Fragment>
        );
      }
      else {
        if (name.startsWith('resume-builder-')) {
          sectionParts.push(
            <div className="block__content--s" key={`section-${sectionIndex}-components`}>
              <div>
                {elementItems}
              </div>
            </div>
          );
          sections.push(
            <div className="bg-brand--white" key={`section-wrapper-${sectionIndex}`}>
              <div>
                {renderPreviousStep()}
                {sectionParts}
              </div>
            </div>
          );
        }
        else {
          sectionParts.push(
            <div className="block__content block__content--s block__content--align-left" key={`section-${sectionIndex}-components`}>
              <div className="my-randstad__content">
                {elementItems}
              </div>
            </div>
          );
          sections.push(
            <div className="block block--my-randstad bg-brand--white block--s" key={`section-wrapper-${sectionIndex}`}>
              <div className="block__wrapper wrapper" style={{flexWrap: "wrap"}}>
                {renderPreviousStep()}
                {sectionParts}
              </div>
            </div>
          );
        }
      }

    });
    return sections;
  };

  const handleEffect = (effect, settings, state) => {
    let shouldApply = false;
    const {dependentField} = effect;
    switch (effect.type) {
      case 'isEmpty':
        if (!state || !state.hasOwnProperty(dependentField) || !state[dependentField]) {
          shouldApply = true;
        }
        break;
      case 'isNotEmpty':
        if (state && state.hasOwnProperty(dependentField) && state[dependentField] === true) {
          shouldApply = true;
        }
        break;
      case 'includes':
        if (state && state.hasOwnProperty(dependentField) && effect.dependentFieldValues.includes(state[dependentField])) {
          shouldApply = true;
        }
        break;
      case 'notIncludes':
        if (!state || (state && state.hasOwnProperty(dependentField) && !effect.dependentFieldValues.includes(state[dependentField]))) {
          shouldApply = true;
        }
        break;
    }

    switch (effect.effect) {
      case 'changeSetting':
        if (effect.settingValue) {
          if (shouldApply) {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingValue}) : effect.settingValue;
          } else {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingDefaultValue}) : effect.settingDefaultValue;
          }
        } else if (effect.settingValueMapping) {
          if (shouldApply) {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingValueMapping[state[dependentField]]}) : effect.settingValueMapping[state[dependentField]];
          } else {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingDefaultValue}) : effect.settingDefaultValue;
          }
        }
        break;
      case 'disabled':
        settings.disabled = shouldApply;
        break;
      case 'hidden':
        settings.hidden = shouldApply;
        break;
      case 'clear':
        if (shouldApply) {
          settings.state = '';
          setFormData((prev) => {
            if (prev && prev[currentStep]) {
              prev[currentStep][settings.name] = '';
            }
            else if (typeof prev === 'object' && !Array.isArray(prev)) {
              Object.entries(prev).forEach(([key, obj]) => {
                if (obj[itemKey] && obj[itemKey][currentStep] && obj[itemKey][currentStep].hasOwnProperty([settings.name])) {
                  prev[key][itemKey][currentStep][settings.name] = '';
                }
              });
            }
            return prev;
          });
        }
        break;
    }
  };

  const handlePrevStepClick = e => {
    e.preventDefault();
    renderStep('prev');
  };

  const renderPreviousStep = () => {
    if (!schema[currentStep] || !schema[currentStep].hasOwnProperty('previousStepText')) {
      return '';
    }
    const {previousStepText} = schema[currentStep];
    return (
      <div className="back-step-button">
        <a href="#" onClick={handlePrevStepClick}>
          <Icon type='arrow-left' className="icon icon--inline fill-brand--blue"/>{intl.formatMessage({id: previousStepText})}
        </a>
      </div>
    );
  };

  return (
    <form method="POST" onSubmit={handleSubmit} noValidate name={`${name}-form`} aria-label={`${name}-form`}>
      {renderComponents()}
    </form>
  )
}

export default injectIntl(Form);
