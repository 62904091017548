const loadImages = () => {
  // Get all elements with data attribute lis.
  const lazyImages = Array.prototype.slice.call(document.querySelectorAll('[data-lis]'));
  // Get all elements with data attribute rlis.
  const lazyResponseImages = Array.prototype.slice.call(document.querySelectorAll('[data-rlis]'));

  lazyImages.forEach(function (image) {
    image.src = image.dataset.lis;
    image.removeAttribute('data-lis');
  });
  lazyResponseImages.forEach(function (source) {
    source.srcset = source.dataset.rlis;
    source.removeAttribute('data-rlis');
  });
};
export default loadImages;
