import React from "react";
import Link from "@Components/Link";
import Icon from "@Components/Icon";

function DashboardItem({children, title, tooltip, linkText, linkPath, isExternal = false, bg = "bg-brand--white", stacked = true, wrapper = true}) {
  const renderToolTip = (text) => {
    if (!text) {
      return null;
    }
    return (
      <span className="tooltip">
      <span className="tooltip__trigger text--alternative">
        <Icon type="help" className="icon icon--inline"/>
        <span className="tooltip__content ">
          <span className="tooltip__text">{text}</span>
          <span className="tooltip__pointer" style={{left: "5px"}}/>
        </span>
      </span>
    </span>
    );
  }

  const renderLink = () => {
    if (!linkPath || !linkText) {
      return '';
    }
    if (isExternal) {
      return (
        <a href={linkPath}>{linkText}</a>
      );
    }
    else {
      return (
        <Link to={linkPath}>{linkText}</Link>
      );
    }
  }

  return (
    <div className={`block block--xs ${bg}`}>
      <div className={`block__wrapper ${stacked ? "block__wrapper--stacked" : ""} ${wrapper ? "wrapper" : ""}`}>
        {title || (linkText && linkPath) ?
          <div className="block__header block__header--split">
            { title && <h2 className="block__title">{title} {renderToolTip(tooltip)}</h2> }
            {renderLink()}
          </div> : null
        }
        {children}
      </div>
    </div>
  );
}

export default DashboardItem;
