import React, {useEffect, useContext, useRef} from 'react';
import {injectIntl} from 'react-intl';
import Icon from '@Components/Icon';
import FormContext from 'formContext';

const SubscriptionJobAlertItem = ({intl, alert, groupType, index}) => {
  const {
    setFormName,
    setFormData,
    setModalOpen,
    setEditedItemKey,
    setErrors,
  } = useContext(FormContext);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  // Opens edit form modal.
  const handleEditJobAlert = () => {
    setErrors();
    const item = alert;
    const data = {};
    data['index'] = index;
    if (item['frequency']) {
      data['frequency'] = item['frequency'];
    }
    if (item['searchParameters']['zipcode']) {
      data['jobAlertLocation'] = item['searchParameters']['zipcode'];
    }
    if (item['searchParameters']['keyword']) {
      data['keyword'] = item['searchParameters']['keyword'];
    }
    if (item['searchParameters']['maxdistance']) {
      data['jobDistance'] = item['searchParameters']['maxdistance'].toString();
    }
    if (item['searchParameters']['state']) {
      data['jobAlertLocation'] = item['searchParameters']['state'];
    }
    // Handle 'remote' location.
    if (item['searchParameters']['city'] === 'remote') {
      data['jobAlertLocation'] = item['searchParameters']['city'];
    }
    if (item['searchParameters']['state'] && item['searchParameters']['city']) {
      data['jobAlertLocation'] = `${item['searchParameters']['city']}, ${item['searchParameters']['state']}`;
    }

    groupType === 'client' ? setFormName('employee-alert') : setFormName('job-alert');
    setEditedItemKey(index);
    setFormData([data]);
    setModalOpen(true);
  };

  const handleEditEmployeeAlert = () => {
    setErrors();
    const item = alert;
    const data = {};
    data['index'] = index;
    if (item['frequency']) {
      data['frequency'] = item['frequency'];
    }
    if (item['searchParameters']['querystring']) {
      data['keyword'] = alert.searchParameters.querystring;
    }
    if (item['searchParameters']['radius']) {
      data['jobDistance'] = item['searchParameters']['radius'].toString();
    }
    if (employeeAlertLocation()) {
      data['jobAlertLocation'] = employeeAlertLocation();
    }

    groupType === 'client' ? setFormName('employee-alert') : setFormName('job-alert');
    setEditedItemKey(index);
    setFormData([data]);
    setModalOpen(true);
  };

  const renderEditButton = () => {
    const disableEditButton = process.env.REACT_APP_DISABLE_EDIT_BUTTON_FROM_ALERTS;
    if (disableEditButton && disableEditButton === 'true') {
      return '';
    }
    return (
      <button className="button--clean" onClick={groupType === 'client' ? handleEditEmployeeAlert : handleEditJobAlert}>
        <Icon type="edit" className="icon icon--inline icon--hover"/>
        <span className="hidden--visually"/>
      </button>
    );
  };

  // Opens ConfirmationModal component from dashboard page.
  const openDeleteConfirmationModal = () => {
    if (index === null) {
      setFormData([{index: null}]);
      setModalOpen(true);
      return;
    }
    const data = {};
    data['alertName'] = alert['alertName'] || '';
    data['index'] = index;

    setFormData([data]);
    setModalOpen(true);
  };

  const jobAlertLocation = () => {
    let location = '';
    if (alert.searchParameters.zipcode) {
      location = alert.searchParameters.zipcode;
    }
    if (alert.searchParameters.state) {
      location = alert.searchParameters.state;
    }
    // Handle 'remote' location.
    if (alert.searchParameters.city && alert.searchParameters.city.toLowerCase() === 'remote') {
      location = alert.searchParameters.city;
    }
    if (alert.searchParameters.state && alert.searchParameters.city) {
      location = `${alert.searchParameters.city}, ${alert.searchParameters.state}`;
    }
    return location;
  };

  const employeeAlertLocation = () => {
    const urlParams = new URLSearchParams(alert.employeeViewLink);

    const state = urlParams.get('state');
    const city = urlParams.get('city');
    const zip = urlParams.get('zip');
    let location = '';
    if (zip) {
      return zip;
    }
    if (state) {
      location = state;
      if (city) {
        location = `${city}, ${state}`;
      }
      return location;
    }
  };

  return (
    <li className="list-item divider" key={alert.id} id={`job-alert-${alert.id}`}>
      <div className="list-item__info">
        <div className="list-item__title list-item__title--split">
          <h3 className="list-item__header list-item__header--badge pr-xl">
            <span className="list-item__link">
              {groupType === 'client' ? alert.searchParameters.querystring : alert.searchParameters.keyword}
            </span>
          </h3>
          <div className="list-item__controls">
            {renderEditButton()}
            <button className="button--clean" onClick={openDeleteConfirmationModal}>
              <Icon type="trash" className="icon icon--inline icon--hover"/>
              <span className="hidden--visually"/>
            </button>
          </div>
        </div>
        <ul className="list-item__meta">
          <li>{intl.formatMessage({id: 'JobAlerts.JobAlert.LocatedSubscription.Text'}, {location: groupType === 'client' ? employeeAlertLocation() : jobAlertLocation()})}</li>
          <li>{intl.formatMessage({id: 'JobAlerts.JobAlert.FrequencyPerEmailSubscription.Text'}, {frequency: alert.frequency.toLowerCase()})}</li>
        </ul>
      </div>
    </li>
  );
};

export default injectIntl(SubscriptionJobAlertItem);
