import React, {useEffect, useState} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {hot} from 'react-hot-loader/root';
import {IntlProvider} from 'react-intl'
import Applications from "@Pages/Applications";
import Dashboard from "@Pages/Dashboard";
import Homepage from "@Pages/Homepage";
import NotFound from "@Pages/NotFound";
import Login from "@Pages/Login";
import Account from "@Pages/Account";
import Edit from "@Pages/Edit";
import Profile from "@Pages/Profile";
import Register from "@Pages/Register";
import Register2Step from "@Pages/Register2Step";
import RegisterConfirmation from "@Pages/RegisterConfirmation";
import ResetPasswordConfirmation from "@Pages/ResetPasswordConfirmation";
import ForgotPassword from "@Pages/ForgotPassword";
import ManageEmailAlerts from "@Pages/ManageEmailAlerts";
import ResumeBuilder from "@Pages/ResumeBuilder";
import ResumeBuilderDownload from "@Pages/ResumeBuilderDownload";
import SubscriptionTalent from '@Pages/SubscriptionTalent';
import {FormProvider} from "formContext";
import axios from 'axios';
import {useCookies} from 'react-cookie';
import GetClientRoutes from 'GetClientRoutes';
import useLanguageSwitcher from 'languageSwitcher';
import RequestConfirmation from "@Pages/RequestConfirmation";
import { Navigation, initEventsRel } from '@rel/experience';

const routes = GetClientRoutes();
let axiosInterceptorAlreadyDefined = false;

function App({currentLanguage, messages, context, authData, links, isEnableRelNavigation}) {
  const navigationProps = {
    myRandstad: {
      text: 'my randstad',
      href: '/my-randstad/',
      user: null,
    },
    logo: {
      title: messages?.['MegaMenu.Logo.Title'],
      href: '/',
    },
    aria: {
      navigation: {
        menu: messages?.['MegaMenu.Aria.Navigation.Menu'],
        navigation: messages?.['MegaMenu.Aria.Navigation.Navigation'],
        close: messages?.['MegaMenu.Aria.Navigation.Close'],
      },
      megaMenu: {
        main: messages?.['MegaMenu.Aria.MegaMenu.Main'],
        close: messages?.['MegaMenu.Aria.MegaMenu.Close'],
      },
    },
  };

  const [myRandstadProps, setMyRandstadProps] = useState(navigationProps.myRandstad);
  const [savedJobProps, setSavedJobProps] = useState(navigationProps.savedJobs);

  // We have to collect all routes outside the App function, because of the
  // pageLoader. If we use the pageLoader inside a function, SSR wont load the
  // components.
  let routeComponents = [];
  const [cookies, setCookie, removeCookie] = useCookies([]);
  // Get the language.
  const lang = currentLanguage ? currentLanguage : process.env.REACT_APP_DEFAULT_LANGUAGE;

  if (!messages && typeof window !== "undefined" && window.__MESSAGES_DATA__) {
    messages = window.__MESSAGES_DATA__;
  }

  const location = useLocation();
  const {initLanguageSwitcher} = useLanguageSwitcher();

  useEffect(() => {
    setMyRandstadProps({
      text: window.myRandstadText ?? myRandstadProps.text,
      href: window.myRandstadUrl ?? myRandstadProps.href,
      user: window.myRandstadUsername ? {
        initials: window.myRandstadUsername?.charAt(0)?.toUpperCase(),
        names: window.myRandstadUsername,
      } : null,
    });
  }, []);

  /**
   * Implement datalayer events from REL for the Mega menu.
   */
  useEffect(() => {
    if (isEnableRelNavigation) {
      initEventsRel();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
    initLanguageSwitcher();
  }, [location]);

  useEffect(() => {
    document.querySelector('html').classList.add('js')
  }, []);

  let isAuthenticated = false;
  if (authData && authData.isAuthenticated) {
    isAuthenticated = authData.isAuthenticated
  }
  else if (typeof window !== "undefined" && window.__AUTH_DATA__) {
    isAuthenticated = window.__AUTH_DATA__.isAuthenticated;
  }

  const ProtectedRoute = ({component: Component, lang, routes, access, ...rest}) => {
    let shouldRedirect = false;
    let redirectTarget;
    if (isAuthenticated && access === 'anonymous') {
      shouldRedirect = true;
      redirectTarget = routes[lang]['dashboard']['url'];
    }
    else if (!isAuthenticated && access === 'authenticated') {
      shouldRedirect = true;
      redirectTarget = routes[lang]['login']['url'];

      removeCookie('MyRandInfo');
      removeCookie('AccessToken');
      removeCookie('RefreshToken');
      // Temporary, to protect my sanity:
      removeCookie('RefreshToken', {path: '/mein-randstad'});
      removeCookie('RefreshToken', {path: '/mein-randstad2'});
    }

    return (
      <Route {...rest} render={props => (
        (!shouldRedirect) ? <Component {...props} /> :
          <Redirect to={redirectTarget}/>
      )}/>
    )
  };

  // Iterate through all supported languages for the country.
  for (let language in routes) {
    // Iterate through all supported routes and components for the country.
    for (let routeName in routes[language]) {
      if (routes[language].hasOwnProperty(routeName)) {
        const route = routes[language][routeName];
        let Component = null;
        switch (route.component) {
          case 'dashboard':
            Component = Dashboard;
            break;
          case 'login':
            Component = Login;
            break;
          case 'account':
            Component = Account;
            break;
          case 'edit':
            Component = Edit;
            break;
          case 'applications':
            Component = Applications;
            break;
          case 'profile':
            Component = Profile;
            break;
          case 'register':
            Component = Register;
            break;
          case 'register-2-step':
            Component = Register2Step;
            break;
          case 'register-confirmation':
            Component = RegisterConfirmation;
            break;
          case 'request-confirmation':
            Component = RequestConfirmation;
            break;
          case 'forgot-password':
            Component = ForgotPassword;
            break;
          case 'manage-email-alerts':
            Component = ManageEmailAlerts;
            break;
          case 'resume-builder':
            Component = ResumeBuilder;
            break;
          case 'resume-builder-download':
            Component = ResumeBuilderDownload;
            break;
          case 'reset-password-confirmation':
            Component = ResetPasswordConfirmation;
            break;
          case 'subscription-talent':
            Component = SubscriptionTalent;
            break;
          case 'homepage':
            Component = Homepage;
            break;
          default:
            break;
        }
        // Add the route in the renderable array.

        let RouteComponent = Route;
        let access;
        if (route.hasOwnProperty('access')) {
          access = route.access;
          RouteComponent = ProtectedRoute;
        }
        let exact=true;

        if (route.hasOwnProperty('exact')) {
          exact=route.exact;
        }
        routeComponents.push(
          <RouteComponent
            exact={exact}
            key={`${language}-${routeName}`}
            path={`${route.url}`}
            component={Component}
            routes={routes}
            lang={lang}
            access={access}
          />
        );
      }
    }
  }
  // Everything that is not defined as route will lead to NotFound page.
  routeComponents.push(<Route key='not-found' component={NotFound}/>);


  if (!axiosInterceptorAlreadyDefined) {
    axiosInterceptorAlreadyDefined = true;
    axios.interceptors.request.use(config => {
      if (!config.hasOwnProperty('skipInterceptor') || config.skipInterceptor === false) {
        config.params = {language: lang};
      }
      return config
    });
  }

  const onIntlError = e => {
    if (e.includes('in Intl.NumberFormat. Using default locale: ')) {
      return
    }
    console.error(e)
  };

  return (
    <IntlProvider defaultLocale={process.env.REACT_APP_DEFAULT_LANGUAGE} locale={lang} messages={messages[lang]} onError={onIntlError}>
      {isEnableRelNavigation && (
        <Navigation
          {...navigationProps}
          myRandstad={myRandstadProps}
          {...links}
        />
      )}
      <FormProvider context={context} authData={authData} messages={messages}>
        <Switch>
          {routeComponents}
        </Switch>
      </FormProvider>
    </IntlProvider>
  )
}

export default hot(App)
