import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function RegionSelectField({intl, settings, ...rest}) {
  const {additionalData: {regions}} = useContext(FormContext);

  const RegionField = () => {
    if (!regions || !Object.keys(regions).length) {
      return null;
    }
    const regionOptions = [];
    regionOptions.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    Object.keys(regions).forEach((region, index) => {
      regionOptions.push({
        value: region,
        label: region,
      });
    });

    settings.options = regionOptions;
    return (
      <SelectField
        settings={settings}
        {...rest}
      />
    )
  };


  return (
    <>
      <RegionField />
    </>
  )
}

export default injectIntl(RegionSelectField);
