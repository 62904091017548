import React from 'react';
import {injectIntl} from 'react-intl';
import SubMenu from '@Components/SubMenu';

const PageHeader = (
  {
    title,
    imageSrc= null,
    imageAltText='',
    bgColor = 'bg-brand--dark-blue',
    skipNavigation = false,
    skipBreadcrumbs = false,
    dynamicBreadcrumbKeyword
  }) => {
  const headerMediaClass = imageSrc ? 'header--media' : '';
  return (
    <>
      <div className={`header header--text ${bgColor} ${headerMediaClass}`}>
        <div className='header__wrapper wrapper'>
          <SubMenu skipNavigation={skipNavigation} skipBreadcrumbs={skipBreadcrumbs} dynamicBreadcrumbKeyword={dynamicBreadcrumbKeyword} />
          <div className='header__content header__content--l content-block header__content--full-width has-breadcrumbs'>
            <h1 className='content-block__title'>
              <span className='content-block__title-top'>{title}</span>
            </h1>
            {imageSrc &&
              <div className="content-block__media hidden--until-l pl-m">
                <img src={imageSrc} alt={imageAltText}/>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(PageHeader);
