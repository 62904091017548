import React, {useContext, useState} from 'react';
import AutoSuggestField from "@FormElements/AutoSuggestField";
import FormContext from "formContext";
import axios from "axios";
import Logger from '@ffw/logger-lib';

function WorkplaceAutosuggest({settings, ...rest}) {
  const {additionalData, setAdditionalData, currentStep, setFormData} = useContext(FormContext);
  const [data, setData] = useState([]);
  const {useWorkplaceNearbyPostalCode} = settings;

  rest.onChange = e => {
    const {value} = e.target;
    if (value) {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/location-autocomplete?text=${value}`)
        .then(result => {
          const newOptions = [];
          if (result.data.items.length) {
            result.data.items.forEach(item => {
              if (item.hasOwnProperty('displayValue')) {
                newOptions.push(item.displayValue)
              }
            });
            setData(result.data.items);
          }
          setAdditionalData(prev => {
            return {
              ...prev,
              workplaces: newOptions
            }
          });
        })
        .catch(error => {
          Logger.error("Error getting workplace suggested values: " + error.message, 'components/WorkplaceAutosuggest/us');
        });
    }
    setFormData(prev => {
      // Set workplaceNearbyPostalCode if we have a match.
      let workplaceNearbyPostalCode = '';
      if (data && value) {
        data.forEach(option => {
          if (option.hasOwnProperty('displayValue') && option.hasOwnProperty('postalCode')) {
            if (option.displayValue === value) {
              workplaceNearbyPostalCode = option.postalCode;
            }
          }
        })
      }
      if (settings.step && !settings.hasItemKey) {
        if (!prev[settings.step] || !prev[settings.step][currentStep]) {
          prev[settings.step] = {
            [currentStep]: {},
          };
        }
        prev[settings.step][currentStep][settings.name] = value;

        if (useWorkplaceNearbyPostalCode !== false) {
          prev[settings.step][currentStep]['workplaceNearbyPostalCode'] = workplaceNearbyPostalCode;
        }
      } else if (settings.step && settings.hasItemKey) {
        if (!prev[settings.step] || !prev[settings.step][settings.itemKey]) {
          prev[settings.step] = {
            [settings.itemKey]: {
              [currentStep]: {},
            },
          };
        }
        prev[settings.step][settings.itemKey][currentStep][settings.name] = value;
        if (useWorkplaceNearbyPostalCode !== false) {
          prev[settings.step][settings.itemKey][currentStep]['workplaceNearbyPostalCode'] = workplaceNearbyPostalCode;
        }
      }
      else {
        if (!prev[currentStep]) {
          prev[currentStep] = {};
        }
        prev[currentStep][settings.name] = value;
        if (useWorkplaceNearbyPostalCode !== false) {
          prev[currentStep]['workplaceNearbyPostalCode'] = workplaceNearbyPostalCode;
        }
      }
      return {
        ...prev
      }
    });
  };
  settings['options'] = additionalData['workplaces'] || [];

  return (
    <AutoSuggestField
      settings={settings}
      {...rest}
    />
  );
}

export default WorkplaceAutosuggest;
