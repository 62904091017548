import {useIntl} from "react-intl";

const useCountriesList = (locale, inSelectListOptionsFormat = false) => {
  const intl = useIntl();
  const emptyValueLabel = intl.formatMessage({id: 'SelectField.EmptyOption.Label'});
  if (!locale) {
    locale = intl.locale;
  }

  let countries = {};
  try {
    countries = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/countries/${locale}.json`);
  }
  catch (e) {
    console.log(`Missing country list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`);
  }

  // Build options for the CountrySelectField component.
  if (inSelectListOptionsFormat) {
    const options = [];
    options.push({value: "", label: emptyValueLabel});
    Object.keys(countries.countries).forEach(code => {
      options.push({value: code, label: countries.countries[code]})
    })

    return options;
  }

  return countries;
}


const useRegionsList = (locale) => {
  const intl = useIntl();
  if (!locale) {
    locale = intl.locale;
  }

  let regions = {};
  try {
    regions = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/regions/${locale}.json`);
  }
  catch (e) {
    console.log(`Missing regions list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`);
  }

  return regions;
}

export {useCountriesList, useRegionsList}
