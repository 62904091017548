import React from "react";
import {injectIntl} from 'react-intl';

function NameDetails({firstName, lastName}) {
  const firstNameAbbr = firstName && typeof firstName === 'string' ? firstName.charAt(0) : '';
  const lastNameAbbr = lastName && typeof lastName === 'string' ? lastName.charAt(0) : '';

  if (!firstName && !lastName) {
    return (
      <div className="name-details">
        -
      </div>
    )
  }

  return (
    <div className="name-details">
      <div className="name-details__placeholder bg-brand--off-white">
        {firstNameAbbr}{lastNameAbbr}
      </div>
      <div className="name-details__name">
        {firstName} {lastName}
      </div>
    </div>
  )
}

export default injectIntl(NameDetails);
