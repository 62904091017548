import React, {useContext} from 'react';
import {injectIntl} from 'react-intl';
import FormContext from 'formContext';

const SocialLoginReset = ({intl}) => {
  const {additionalData} = useContext(FormContext);
  return additionalData?.social?.isResettable ? (
    <button type="button" className={'button button--m button--full-width mt-xs'} onClick={additionalData?.social?.resetCallback}>
      {intl.formatMessage({id: 'Login.Form.ResetSocialLoginButton.Text'})}
    </button>
  ) : null;
};


export default injectIntl(SocialLoginReset);
