import React from 'react';
import {injectIntl} from "react-intl";

function InputField({intl, settings, ...rest}) {
  const {inputType, label, placeholder, required = true, disabled = false, hidden = false, state, errors, description = false, divClasses = ''} = settings || {};
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));

  const id = `field--input--${name}`;

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label" />;
    }
    else {
      return (
        <label className="form-group__label" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  if (hidden) {
    return '';
  }

  const renderDescription = () => {
    if (!description) {
      return '';
    }
    return (
      <div className="form-group__message">{intl.formatMessage({id: description})}</div>
    )
  };

  return (
    <div className={`form-group form-group--input ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      {renderLabel()}
      <div className="form-group__input">
        <input
          type={(inputType) ? inputType : 'text'}
          name={name}
          id={id}
          required={required}
          disabled={disabled}
          placeholder={placeholder && intl.formatMessage({id: placeholder}).toLowerCase()}
          value={(state && state.hasOwnProperty(name) ? state[name] : '')}
          {...rest}
        />
      </div>
      {renderDescription()}
      {renderErrors()}
    </div>
  );

}

export default injectIntl(InputField);
