import React from 'react';
import GetClientRoutes from 'GetClientRoutes';
import {useRouteMatch} from "react-router-dom";
import {useIntl} from "react-intl";

function GetRouteFromUrl() {
  let {locale} = useIntl();
  const currentUrl = useRouteMatch().path;
  const clientRoutes = GetClientRoutes()[locale];
  for (const name in clientRoutes) {
    if (clientRoutes.hasOwnProperty(name)) {
      const route = clientRoutes[name];
      if (route.url === currentUrl) {
        return {name, ...route}
      }
    }
  }
  return null;
}

export default GetRouteFromUrl;
