import React, {useEffect, useRef} from 'react';
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function DatePicker({intl, settings, onChange, ...rest}) {
  const ref = useRef();
  const {locale} = intl;
  const {
    label,
    placeholder,
    hidden = false,
    required = true,
    disabled = false,
    defaultDate = "",
    altFormat = "Y-m-d",
    dateFormat = "Y-m-d",
    state,
    errors,
    divClasses = ''
  } = settings || {};
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  useEffect(() => {
    if (!ref.current) return;

    const { DatePicker } = require("@ffw/randstad-local-orbit/js/components/date-picker")
    new DatePicker(ref.current);

    window.addEventListener('onFlatDatePickerChange', () => {
      if (!ref.current) return;

      const target = ref.current.querySelector('input.input');
      target.name = name;
      target.disabled = disabled;
      onChange({
        target: target
      })
    })
  }, [hidden, disabled]);

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));

  const id = `field--datepicker--${name}`;

  let limitDateProp = {};
  if (settings.onlyPastDates) {
    const today = new Date();
    const todayString = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
    limitDateProp = {
      "data-rs-datepicker-max-date": todayString
    }
  }

  const fieldProps = {
    type: "text",
    "data-rs-datepicker-input": "",
    "data-rs-datepicker-default-date": defaultDate,
    "data-rs-datepicker-language": locale,
    "data-rs-datepicker-alt-format": altFormat,
    "data-rs-datepicker-date-format": dateFormat,
    name: name,
    ...limitDateProp
  };

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label" />;
    }
    else {
      return (
        <label className="form-group__label" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  if (hidden) {
    return '';
  }

  if (disabled) {
    return (
      <div className={`form-group form-group--datepicker ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
        {renderLabel()}
        <div className="form-group__input ">
          <div ref={ref} data-rs-datepicker="">
            <button className="button--clean" type="button"/>
            <input
              placeholder={placeholder && intl.formatMessage({id: placeholder})}
              tabIndex="-1"
              className="flatpickr"
              disabled={disabled}
              required={required}
              defaultValue={(state && state.hasOwnProperty(name) ? state[name] : '')}
              data-rs-datepicker-language={locale}
              {...fieldProps}
              {...rest}
            />
            <span className="button--icon-only button--form-group-style"/>
          </div>
        </div>
        {renderErrors()}
      </div>
    );
  }

  return (
    <div className={`form-group form-group--datepicker ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      {renderLabel()}
      <div className="form-group__input ">
        <div ref={ref} data-rs-datepicker="">
          <button className="button--clean" type="button"/>
          <input
            placeholder={placeholder && intl.formatMessage({id: placeholder})}
            tabIndex="-1"
            className="flatpickr"
            required={required}
            defaultValue={(state && state.hasOwnProperty(name) ? state[name] : '')}
            data-rs-datepicker-language={locale}
            {...fieldProps}
            {...rest}
          />
          <span className="button--icon-only button--form-group-style">
            <Icon type='calendar'/>
          </span>
        </div>
      </div>
      {renderErrors()}
    </div>
  );

}

export default injectIntl(DatePicker);
