import React from 'react';

const useJobCardHelper = () => {
  const sanitizePathParam = (param) => {
    // Possibly different for every country. Possibly transliterate needed.
    // For PT we are doing what we did for UK, and then in addition removing accents from characters.
    return String(param)
      .trim()
      .toLowerCase()
      .replace(/[.,!?/&#:%()]/g, "")
      .replace(/[ -]+/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }


  function buildJobUrl(jobTitle, location, lobId, atsReference) {
    let title = jobTitle.toLowerCase().replace(/[^a-zA-Z\d:]+/g,"-");
    title = title.slice(-1) === '-' ? title.slice(0, -1) : title;
    let city = location && location.city ?
      '_' + location.city.toLowerCase().replace(/ /g,"-") : '';
    const encodedUri = encodeURIComponent(`${title}${city}`);

    return `/jobs/${lobId}/${atsReference}/${encodedUri}/`;
  }


  /**
   * Prepare job feed for printing.
   *
   * @param {array} jobData Raw feed from api.
   *
   * @return {array} Ready for printing fields.
   */
  const formatJobRawFeed = (jobData) => {
    let url = buildJobUrl(
      jobData.title,
      jobData.jobLocation,
      jobData.lobId,
      jobData.atsReference
    );

    let json_data = {
      title: jobData.title,
      description: jobData.description,
      url: url,
      jobPostDate: jobData.startDate,
      jobExpireDate: jobData.expireDate,
      jobLocation: {},
      salary: {},
      opCoName: jobData.opCoName,
      isRemote: jobData.isRemote ? jobData.isRemote : null,
    };

    if (jobData.jobLocation && jobData.jobLocation.state) json_data["jobLocation"]["region"] = jobData.jobLocation.state;
    if (jobData.jobLocation && jobData.jobLocation.city) json_data["jobLocation"]["city"] = jobData.jobLocation.city;

    if (jobData.jobLocation && jobData.jobLocation.latitude) json_data["jobLocation"]["latitude"] = jobData.jobLocation.latitude;
    if (jobData.jobLocation && jobData.jobLocation.longitude) json_data["jobLocation"]["longitude"] = jobData.jobLocation.longitude;

    if (jobData.salary && jobData.salary.type) json_data["salary"]["type"] = jobData.salary.type;
    if (jobData.salary && jobData.salary.currency) json_data["salary"]["currency"] = jobData.salary.currency;
    if (jobData.salary && jobData.salary.max) json_data["salary"]["max"] = jobData.salary.max;
    if (jobData.salary && jobData.salary.min) json_data["salary"]["min"] = jobData.salary.min;
    if (jobData.type) json_data["type"] = jobData.type;
    if (jobData.workHours) json_data["detaworkHours"]= jobData.workHours;
    if (jobData.employmentType) json_data["employmentType"] = jobData.employmentType;
    if (jobData.createdDate) json_data["createdDate"] = jobData.createdDate;
    if (jobData.cardType) json_data["cardType"] = jobData.cardType;

    return json_data;
  }

  return { sanitizePathParam, formatJobRawFeed }
}

export default useJobCardHelper;
