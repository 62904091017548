import React from 'react';
import {injectIntl} from 'react-intl';
import Link from '@Components/Link';
import getRouteByName from 'getRouteByName';
import getRouteFromUrl from 'GetRouteFromUrl';

function Breadcrumbs({intl, dynamicBreadcrumbKeyword}) {
  const currentRoute = getRouteFromUrl();
  let parentBreadcrumb;
  if (currentRoute && currentRoute['parentBreadcrumbComponent']) {
    parentBreadcrumb = getRouteByName('dashboard');
  } else if (currentRoute && currentRoute['parentBreadcrumbTitle'] && currentRoute['parentBreadcrumbUrl']) {
    parentBreadcrumb = {
      'url': currentRoute['parentBreadcrumbUrl'],
      'breadcrumbTitle': currentRoute['parentBreadcrumbTitle'],
    };
  }

  if (!currentRoute['breadcrumbTitle']) {
    return '';
  }
  const title = dynamicBreadcrumbKeyword ?
    intl.formatMessage({id: currentRoute['breadcrumbTitle']}, {keyword: dynamicBreadcrumbKeyword})
    : intl.formatMessage({id: currentRoute['breadcrumbTitle']});

  return (
    <div className="navigation__bottom">
      <nav className="breadcrumb" aria-label="breadcrumb">
        <a
          href={parentBreadcrumb ? parentBreadcrumb.url : '/'}
          className="breadcrumb__link hidden--from-l">
          {intl.formatMessage({id: parentBreadcrumb ? parentBreadcrumb['breadcrumbTitle'] : 'Breadcrumb.Home'})}
        </a>
        <ul className="breadcrumb__list hidden--until-l">
          <li className="breadcrumb__item">
            <a href="/" className="breadcrumb__link">{intl.formatMessage({id: 'Breadcrumb.Home'})}</a>
          </li>
          {parentBreadcrumb &&
            <li className="breadcrumb__item">
              <a
                href={parentBreadcrumb.url}
                className="breadcrumb__link">
                {intl.formatMessage({id: parentBreadcrumb['breadcrumbTitle']})}
              </a>
            </li>
          }
          {currentRoute['breadcrumbTitle'] &&
            <li className="breadcrumb__item">{title}</li>
          }
        </ul>
      </nav>
    </div>
  );
}

export default injectIntl(Breadcrumbs);
