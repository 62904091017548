import React, {useRef, useEffect, useContext} from 'react';
import {injectIntl} from 'react-intl';
import {createIntl, createIntlCache} from 'react-intl';
import Icon from '@Components/Icon';
import CardMeta from '@Components/CardMeta';
import CardBackside from '@Components/CardBackside';
import useDateHelper from 'dateHelper';
import FormContext from 'formContext';

function JobCard({ job, onFavIconClick, intl, bg = "", hideLink }) {
  const {translations} = useContext(FormContext);
  const {customDateFormat, dateHasExpired} = useDateHelper();
  const {url, createdDate, title, description, expireDate, jobLocation, lobId, salary, type, employmentType, cardType, opCoName} = job || {};
  const details = {
    region:  jobLocation && jobLocation.region ? jobLocation.region : '',
    city: jobLocation && jobLocation.city ? jobLocation.city : '',
    isRemote: job.isRemote,
    salaryData: salary,
    jobType: type,
    employmentType: employmentType,
    opCoName: opCoName,
  };
  const orbitRef = useRef();
  // Use applicationExpired of the job item, when handling application cards.
  // It is set when using the Application component.
  const expired = dateHasExpired(expireDate);
  const useLowerCase = process.env.REACT_APP_USE_LOWER_CASE === "true";

  const jobLang = url[0] === '/' && url[3] === '/' ? url.substr(1, 2) : process.env.REACT_APP_DEFAULT_LANGUAGE;
  if (jobLang) {
    const cache = createIntlCache();
    intl = createIntl({
      locale: jobLang,
      messages: translations[jobLang]
    }, cache);
  }
  const createdDateTimestamp = Date.parse(createdDate);
  const dateText = cardType === 'application'
    ? 'Applications.MyApplications.Card.AppliedOn'
    : 'JobCard.PublishedOn';
  const dateFormatted = Number.isInteger(createdDateTimestamp) ? customDateFormat(createdDateTimestamp / 1000, 'M d Y', intl) : '';

  useEffect(() => {
    if (!orbitRef.current) return;

    const { Card } = require("@ffw/randstad-local-orbit/js/components/card");
    new Card(orbitRef.current);
  }, []);

  return (
    <li
      className={`cards__item ${expired ? "cards__item--disabled" : ""} ${bg}`}
      ref={orbitRef}
      data-rs-card=""
      data-rs-carousel-card=""
    >
      <div className="cards__header">
        <h3 className="cards__title">
          {hideLink ?
            <div>
              {useLowerCase ? title.toLowerCase() : title}
              <span className="make-entire-card-clickable"></span>
            </div> :
            <a href={url} className="cards__link">
              {useLowerCase ? title.toLowerCase() : title}
              <span className="make-entire-card-clickable"></span>
            </a>}
        </h3>
      </div>

      <CardMeta intl={intl} details={details} />

      <div className="cards__footer">
        <div className="cards__time-info">
          <span className="cards__date text--alternative">
            {intl.formatMessage({id: dateText})} {dateFormatted}
          </span>
        </div>
        <div className="cards__info-wrapper" data-rs-card-show-backside="" tabIndex="0">
          <span className="cards__info-button text--alternative">
            <Icon type="info" className="icon icon--inline" />
          </span>
        </div>
      </div>

      {hideLink ?
        <CardBackside text={description} label={intl.formatMessage({id: 'JobCard.ShowDetails'})} /> :
        <CardBackside text={description} label={intl.formatMessage({id: 'JobCard.ShowDetails'})} url={url} />
      }
    </li>
  );
}

export default injectIntl(JobCard)
