import React, {useContext} from 'react';
import axios from 'axios';
import { useCookies } from "react-cookie";
import FormContext from 'formContext';

const useResumeBuilderHelper = () => {
  const {formData, currentStep, getFormSchema} = useContext(FormContext);
  const [cookies, setCookie, removeCookie] = useCookies([])

  /**
   * Saves resume data in session storage.
   *
   * @return Resume data object.
   */
  const setResumeData = (form, step, index = false) => {
    const schema = getFormSchema(form);
    let submitData = {};
    if (index === false) {
      schema[0].data[0].components.forEach(item => {
        const name = item.settings.name;
        if (name && formData[step] && formData[step][currentStep] && formData[step][currentStep][name]) {
          submitData[name] = formData[step][currentStep][name];
        }
      });
    }
    else {
      schema[0].data[0].components.forEach(item => {
        const name = item.settings.name;
        if (name && formData[step] && formData[step][index][currentStep] && formData[step][index][currentStep][name]) {
          submitData[name] = formData[step][index][currentStep][name];
        }
      });
    }

    return axios.post(`${process.env.REACT_APP_API_PREFIX}/resume-builder`, {
      step: step,
      formData: submitData
    }).then(response => {
      if (response.data
        && response.data.submittedData) {
        const resumeData = JSON.parse(sessionStorage.getItem('resumeData')) || {};
        if (index !== false) {
          if (typeof resumeData[step] === 'undefined') {
            resumeData[step] = [response.data.submittedData];
          }
          else {
            resumeData[step][index] = response.data.submittedData;
          }
        }
        else {
          resumeData[step] = response.data.submittedData;
        }
        sessionStorage.setItem('resumeData', JSON.stringify(resumeData));
        sessionStorage.setItem('resumeDataUpdated', Date.now());
        return resumeData;
      }
    }).catch(error => {
      throw error;
    });
  };

  /**
   * Gets resume data from session storage.
   *
   * @return Session object.
   */
  const getResumeData = () => {
    const resumeData = JSON.parse(sessionStorage.getItem('resumeData')) || {};
    const resumeDataUpdated = sessionStorage.getItem('resumeDataUpdated');
    if (resumeDataUpdated) {
      const expire = parseInt(resumeDataUpdated) + (30 * 60000);
      const now = Date.now();
      if (now > expire) {
        sessionStorage.removeItem('resumeData');
        sessionStorage.removeItem('resumeDataUpdated');
        removeCookie("ResumeBuildSave");
        return {};
      }
    }
    return resumeData;
  };

  /**
   * Deletes resume data item from session storage.
   */
  const deleteResumeDataItem = (step, index = null) => {
    const resumeData = JSON.parse(sessionStorage.getItem('resumeData')) || {};
    if (index !== null && index > -1) {
      if (typeof resumeData[step] !== 'undefined') {
        resumeData[step].splice(index, 1);
        if (resumeData[step].length === 0) {
          delete resumeData[step];
        }
      }
    }
    else {
      if (typeof resumeData[step] !== 'undefined') {
        delete resumeData[step];
      }
    }
    sessionStorage.setItem('resumeData', JSON.stringify(resumeData));
    sessionStorage.setItem('resumeDataUpdated', Date.now());
  };

  /**
   * Formats location.
   */
  const formatLocationData = (location, contactInfo = true, geoData = {}) => {
    const zipCityRegex = new RegExp(process.env.REACT_APP_ZIPCITY_REGEX);
    if (zipCityRegex.test(location)) {
      const locationData = location.split('-');
      if (locationData.length > 1) {
        return contactInfo ? locationData[1].trim() + ' ' + locationData[0].trim() : locationData[1].trim();
      }
      else {
        return location;
      }
    }
    else if (location.split(',').length === 1 && geoData.stateName) {
      return geoData.stateName;
    }
    else if (contactInfo && location.split(',').length > 1 && Object.keys(geoData).length) {
      if (geoData.zipCode) {
        return `${location} ${geoData.zipCode}`;
      }
      else {
        return location;
      }
    }
    else {
      return location;
    }
  };

  return {
    setResumeData,
    getResumeData,
    deleteResumeDataItem,
    formatLocationData,
  }
};

export default useResumeBuilderHelper;
