import React, {useEffect, useState, useRef, useContext} from 'react';
import {injectIntl} from 'react-intl';
import FormContext from 'formContext';

function PhoneField({intl, settings, formName, itemKey}) {
  const phone = useRef(null);
  const {formData, setFormData, errors, currentStep, getFormSchema} = useContext(FormContext);
  const {
    label,
    name,
    description,
    placeholder,
    required = true,
    hidden = false,
    disabled = false,
    numberFormat = '(***) ***-****',
    inputMask = '(___) ___-____',
  } = settings || {};
  const schema = formName ? getFormSchema(formName) : [];
  const state = settings.state ?? {};
  const [localState, setLocalState] = useState('(___) ___-____');
  const id = `field--input--${name}`;

  useEffect(() => {
    if (Array.isArray(formData) && formData.length) {
      if (formData[currentStep] && formData[currentStep][name]) {
        setLocalState(doFormat(formData[currentStep][name], numberFormat, inputMask));
      }
    } else if (typeof formData === 'object' && schema && formName && formName.startsWith('resume-builder-')) {
      const {formDataObject} = schema[0].data[0];
      if (formData[formDataObject] && formData[formDataObject][itemKey || 0][name]) {
        setLocalState(doFormat(formData[formDataObject][itemKey || 0][name], numberFormat, inputMask));
      }
    }
  }, [formData]);

  useEffect(() => {
    setFormData((prev) => {
      const formDataObject = schema.length ? schema[0].data[0].formDataObject : false;
      if (formDataObject) {
        if (!Array.isArray(prev) && prev[formDataObject]) {
          prev[formDataObject][itemKey || 0] = {
            ...prev[formDataObject][itemKey || 0],
            [name]: removeFormat(localState),
          };
        } else if (Array.isArray(prev) && !prev.length) {
          prev = {
            [formDataObject]: {},
          };
          prev[formDataObject][itemKey || 0] = {
            [name]: removeFormat(localState),
          };
        }
      } else if (Array.isArray(prev) && prev.length) {
        if (prev[currentStep]) {
          prev[currentStep] = {
            ...prev[currentStep],
            [name]: removeFormat(localState),
          };
        }
      } else if (Array.isArray(prev) && !prev.length) {
        prev[currentStep] = {
          [name]: removeFormat(localState),
        };
      } else {
        prev[currentStep] = {
          ...prev[currentStep],
          [name]: removeFormat(localState),
        };
      }

      return prev;
    });

    const val = doFormat(removeFormat(localState), numberFormat);
    const elem = phone.current;
    if (elem.createTextRange) {
      const range = elem.createTextRange();
      range.move('character', val.length);
      range.select();
    } else if (elem.selectionStart) {
      elem.setSelectionRange(val.length, val.length);
    }
  }, [localState]);

  if (hidden) {
    return '';
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length)));

  const doFormat = (x, pattern, mask) => {
    const strippedValue = x.replace(/[^0-9]/g, '');
    const chars = strippedValue.split('');
    let count = 0;

    let formatted = '';
    for (let i = 0; i < pattern.length; i++) {
      const c = pattern[i];
      if (chars[count]) {
        if (/\*/.test(c)) {
          formatted += chars[count];
          count++;
        } else {
          formatted += c;
        }
      } else if (mask) {
        if (mask.split('')[i]) {
          formatted += mask.split('')[i];
        }
      }
    }

    return formatted;
  };

  const removeFormat = (value) => {
    return value.replace(/\D/g, '');
  };

  const format = (event) => {
    const elem = event.target;
    setLocalState(doFormat(elem.value, numberFormat, inputMask));
  };

  const handleChange = (event) => {
    event.persist();
    format(event);
  };

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label"/>;
    } else {
      return (
        <label className="form-group__label" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required &&
            <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderDescription = () => {
    if (!description) {
      return '';
    }
    return (
      <div className="form-group__message">
        {intl.formatMessage({id: description})}
      </div>
    );
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>,
      );
    }
    return renderable;
  };

  const renderPhoneField = () => (
    <div className={`form-group ${hasErrors ? 'form-group--error' : ''}`}>
      {renderLabel()}
      <input ref={phone}
             type="text"
             name={name}
             id={id}
             value={localState}
             required={required}
             placeholder={placeholder}
             disabled={disabled}
             onChange={handleChange}
             onClick={format}
      />
      {renderDescription()}
      {renderErrors()}
    </div>
  );

  return (
    <>
      {renderPhoneField()}
    </>
  );
}

export default injectIntl(PhoneField);
