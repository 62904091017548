import React from 'react';
import Icon from "@Components/Icon";

const CardBackside = ({ text, label, url }) => {
  const description = text ? text.replace(/<[^>]*>?/gm, "") : "";
  const noLinkClass = url ? '' : 'cards__backside-footer--no-link';

  return (
    <div className="cards__backside-card">
      <div className="cards__backside-description">{description}</div>
      <div className={`cards__backside-footer ${noLinkClass}`}>
        <a href={url} className="cards__backside-footer--horizontal cards__backside-footer--job-link" aria-label="" tabIndex="-1">
          {url && (
            <>
              <Icon type='eye' className='icon icon--inline' />
              {label}
            </>
          )}
        </a>
        <div data-rs-card-hide-backside="" className="cards__backside-footer__button cards__backside-footer--close-backside" tabIndex="0">
          <Icon type="close" className="icon icon--inline" />
        </div>
      </div>
    </div>
  );
};

export default CardBackside;
