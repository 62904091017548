import React from 'react';
import Link from "@Components/Link";
import {injectIntl} from "react-intl";
import GetRouteByName from 'getRouteByName';

function TextWithLink({intl, settings}) {
  const {text, link, linkText, linkClasses = "", divClasses = "form-group", pClasses = "", isExternal = false, hidden = false} = settings || {};

  const renderLink = () => {
    if (link && linkText) {
      const url = GetRouteByName(link).url;
      return isExternal ?
          <a href={url} className={linkClasses}>{intl.formatMessage({id: linkText})}</a> :
          <Link to={url} className={linkClasses}>{intl.formatMessage({id: linkText})}</Link>
    }
  };

  return hidden ? null : (
      <div className={divClasses}>
        <p className={pClasses}>{intl.formatMessage({id: text})}</p>
        {renderLink()}
      </div>
  );
}

export default injectIntl(TextWithLink);
