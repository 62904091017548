import React from "react";

let breadcrumbItems = [];

function addFirstItem(url, name) {
  breadcrumbItems = [{url, name}];
}

function addItem(url, name) {
  breadcrumbItems.push({url: url, name: name});
}

function getScript() {
  const breadcrumbListScript = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [],
  };
  breadcrumbItems.forEach((item, index) => {
    breadcrumbListScript.itemListElement.push({
      '@type': 'ListItem',
      'position': index + 1,
      'item': {
        '@id': `${process.env.REACT_APP_BASE_URL}${item.url}`, 'name': item.name,
      },
    });
  });
  return (
    <script type="application/ld+json">
      {JSON.stringify(breadcrumbListScript)}
    </script>
  );
}

export default {addItem, addFirstItem, getScript}
