import 'fastestsmallesttextencoderdecoder';
import 'react-app-polyfill/ie11';
import 'unorm'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './client/App'
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'rnd-helmet';
import { CookiesProvider } from 'react-cookie';

let supportedLanguages = [];
// Load the routes for the specific country.
const routes = require(`./config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
// Iterate through all supported languages for the country.
for (let lang in routes) {
  if (routes.hasOwnProperty(lang)) {
    supportedLanguages.push(lang);
  }
}

let currentLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
// Get the current language based on the URL.
const pathname = window.location.pathname;
supportedLanguages.forEach(lang => {
  if (pathname.startsWith(`/${lang}/`)) {
    currentLanguage = lang
  }
});

let currentLangRel;
if (process.env.REACT_APP_COUNTRY_LOCALES) {
  currentLangRel = process.env.REACT_APP_COUNTRY_LOCALES;
} else {
  currentLangRel = currentLanguage;
}

const bootstrap =
  <HelmetProvider>
    <BrowserRouter>
      <CookiesProvider>
      <App
          currentLanguage={currentLanguage}
          links={{
            items: window.myRandstadMenuItem ? [...(window.__LINKS__?.[currentLangRel]?.main || []), window.myRandstadMenuItem] : (window.__LINKS__?.[currentLangRel]?.main || []),
            utilityItems: window.__LINKS__?.[currentLangRel]?.utility,
          }}
          isEnableRelNavigation={window.isEnableRelNavigation}
        />
      </CookiesProvider>
    </BrowserRouter>
  </HelmetProvider>;

ReactDOM.hydrate(
  bootstrap,
  document.getElementById('root')
);
