const Joi = require('@hapi/joi');

module.exports = (intl) => {
  return {
    schema: Joi.object().keys({
      email: Joi.string()
          .required()
          .pattern(/^\w+([-+_.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
          .lowercase()
          .messages({
            'any.required': intl.formatMessage({id: 'Schema.Signup.Email.Required'}),
            'string.empty': intl.formatMessage({id: 'Schema.Signup.Email.Required'}),
            'string.pattern.base': intl.formatMessage({id: 'Schema.Signup.Email.EmailString'}),
          }),
      password: Joi.string()
          .required()
          .min(8)
          .max(99)
          .custom((value) => {
            if (value.toUpperCase() === value || value.toLowerCase() === value) {
              throw new Error('error no uppercase or lowercase'); // No need to translate this, it's not sent.
            }
            return value;
          })
          .pattern(/[0-9]/)
          .messages({
            'string.empty': intl.formatMessage({id: 'Schema.Signup.Password.Required'}),
            'any.required': intl.formatMessage({id: 'Schema.Signup.Password.Required'}),
            'string.min': intl.formatMessage({id: 'Schema.Signup.Password.Min'}),
            'string.max': intl.formatMessage({id: 'Schema.Signup.Password.Max'}),
            'string.pattern.base': intl.formatMessage({id: 'Schema.Signup.Password.Pattern'}),
            'any.custom': intl.formatMessage({id: 'Schema.Signup.Password.Custom'}),
          })
          .strip(),
      captchaToken: Joi.any().strip()
    }),
  };
};
