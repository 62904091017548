import firebase from 'firebase';

/**
 * Returns firebase web app.
 *
 * @param {string} appName
 * @return {firebase.app.App}
 */
const getApp = (appName='[DEFAULT]') => {
  let firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  if (typeof window !== 'undefined') {
    firebaseApiKey = window.firebaseApiKey;
  }
  return firebase.apps.find((item) => item.name === appName) || firebase.initializeApp({
    apiKey: firebaseApiKey,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  }, appName);
};

/**
 * Returns current logged in user.
 *
 * @return {firebase.User}
 */
const getUser = () => {
  return getApp().auth().currentUser;
};

/**
 * Opens login popup for Google firebase auth provider.
 *
 * @param {boolean} rememberMe
 *    Indicates if user needs to be saved locally.
 * @return {Promise<firebase.auth.UserCredential>}
 */
const loginWithGoogle = (rememberMe = true) => {
  const app = getApp();
  return app.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
    return app.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
  });
};

/**
 * Opens login popup for Facebook firebase auth provider.
 *
 * @return {Promise<firebase.auth.UserCredential>}
 */
const loginWithFacebook = () => {
  const app = getApp();
  return app.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
    return app.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider());
  });
};

/**
 * Signs in with email and password.
 *
 * @param {string} email
 *   User email.
 * @param {string} password
 *   User password.
 * @return {Promise<firebase.auth.UserCredential>}
 */
const loginWithEmailPassword = async (email, password ) => {
  const app = getApp();
  return getApp().auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
    return app.auth().signInWithEmailAndPassword(email, password);
  });
};

/**
 * Registers user with email and password.
 *
 * @param {string} email
 *   User email.
 * @param {string} password
 *   User password.
 * @param {boolean} rememberMe
 *    Indicates if user needs to be saved locally.
 * @return {Promise<firebase.auth.UserCredential>}
 */
const registerWithEmailPassword = (email, password, rememberMe = true) => {
  const app = getApp();
  return app.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
    return app.auth().createUserWithEmailAndPassword(email, password);
  });
};

/**
 * Verify reset password reset code.
 *
 * @param {string} code
 *   Firebase code.
 * @return {Promise<firebase.auth.verifyPasswordResetCode>}
 */
const verifyResetCode = (code) => {
  const app = getApp();
  return app.auth().verifyPasswordResetCode(code);
}

/**
 * Change user password.
 *
 * @param {string} newPassword
 *   New user password.
 * @param {string} code
 *   Firebase code.
 * @return {Promise<firebase.auth.confirmPasswordReset>}
 */
const saveNewPassword = (code, newPassword) => {
  const app = getApp();
  return app.auth().confirmPasswordReset(code, newPassword);
}

export {
  getApp,
  getUser,
  loginWithEmailPassword,
  loginWithFacebook,
  loginWithGoogle,
  registerWithEmailPassword,
  verifyResetCode,
  saveNewPassword,
};
