import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {Sha256} from '@aws-crypto/sha256-browser';
import {toHex} from '@aws-sdk/util-hex-encoding';
import ResumeBuilderStepForm from '@Components/ResumeBuilderStepForm';
import Accordion from '@Components/Accordion';
import Icon from '@Components/Icon';
import useResumeBuilderHelper from 'resumeBuilderHelper';
import {pushResumeBuilderEvent} from 'dataLayerHelper';

function ResumeBuilderStep({intl, staticContext, step, settings, setLastSavedSection}) {
  const [editing, setEditing] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [stepData, setStepData] = useState([]);
  const [collapse, doCollapse] = useState(0);
  const [hashedEmail, setHashedEmail] = useState('');
  const {getResumeData} = useResumeBuilderHelper();

  useEffect(() => {
    getSessionData();
  }, []);

  const cancelHandler = () => {
    // Collapse Accordion.
    doCollapse(prev => prev + 1);
  }

  const handleSave = event => {
    getSessionData();
    setAddMode(false);
    setEditing(false);
    setLastSavedSection(step);
    pushResumeBuilderEvent('save', step, hashedEmail);
  };

  const getSessionData = () => {
    const resumeData = getResumeData();
    // Set hash email.
    if (Object.keys(resumeData).length && resumeData.contact_info) {
      const hash = new Sha256();
      hash.update(resumeData.contact_info.email);
      hash.digest().then((result) => {
        setHashedEmail(toHex(result));
      });
    }
    // Set form data.
    if (Object.keys(resumeData).length && resumeData[step]) {
      setStepData(resumeData[step]);
    }
    else {
      setStepData([]);
    }
  };

  const onAddHandler = event => {
    setAddMode(true);
    setEditing(false);
    if (step === 'skills') {
      pushResumeBuilderEvent('addAnother', 'skills', hashedEmail);
    }
  };

  const handleEdit = index => {
    setEditing(index);
    setAddMode(false);
  };

  let stepContent = '';
  let expanded = true;
  if (stepData.length && editing === false && !addMode) {
    const addButtonText = `ResumeBuilder.Form.Add${step}.Text`;
    let skillData = [];
    let stepItems = '';
    if (stepData[0] && stepData[0].skill) {
      stepData.forEach((item, i) => {
        if (item !== 'undefined') {
          skillData.push(item.skill);
        }
      });
    }

    if (skillData.length) {
      const data = {
        0: skillData
      };
      stepItems = <ResumeBuilderStepForm key={step} step={step} form={settings.form} index={0} stepData={data} handleSave={handleSave} handleAdd={onAddHandler} handleEdit={handleEdit} />;
    }
    else {
      stepItems = stepData.map((item, index) => {
        return <ResumeBuilderStepForm key={step + '-' + index} step={step} form={settings.form} index={index} stepData={stepData} handleSave={handleSave} handleEdit={handleEdit} />;
      });
    }
    stepContent = (
      <div>
        <button className='button button--s' onClick={onAddHandler}>
          <span className='icon icon--inline'>
            <Icon type='add' />
          </span>
          {intl.formatMessage({id: addButtonText})}
        </button>
        {stepItems}
      </div>
    );
  }
  else if (stepData.length && editing === false && addMode) {
    let skillData = [];
    let stepItems = '';
    if (stepData[0].skill) {
      stepData.forEach((item, i) => {
        if (item !== 'undefined') {
          skillData.push(item.skill);
        }
      })
    }

    if (skillData.length) {
      const data = {
        0: skillData
      };
      stepItems = <ResumeBuilderStepForm key={step} step={step} form={settings.form} index={0} stepData={data} handleSave={handleSave} addMode={addMode} />;
    }
    else {
      stepItems = stepData.map((item, index) => {
        return <ResumeBuilderStepForm key={step + '-' + index} form={settings.form} step={step} index={index} stepData={stepData} handleEdit={handleEdit} />;
      });
    }
    stepContent = (
      <div>
        <ResumeBuilderStepForm step={step} form={settings.form} index={stepData.length} handleEdit={handleEdit} handleSave={handleSave} addCancelButton={true} />
        {stepItems}
      </div>
    );
  }
  else if (stepData.length && editing !== false) {
    stepContent = <ResumeBuilderStepForm step={step} form={settings.form} index={editing} handleEdit={handleEdit} handleSave={handleSave} editMode={true} addCancelButton={true}/>;
  }
  else {
    expanded = false;
    stepContent = <ResumeBuilderStepForm step={step} form={settings.form} index={0} handleEdit={handleEdit} handleSave={handleSave} addCancelButton={true} cancelHandler={cancelHandler} />;
  }

  return (
    <li className='link-list__item'>
      <Accordion title={intl.formatMessage({id: settings.title})} children={stepContent} expanded={expanded} handleCollapse={collapse} />
    </li>
  );
}

export default injectIntl(ResumeBuilderStep);
