import React, {useContext} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Link from '@Components/Link';
import Breadcrumbs from '@Components/Breadcrumbs';
import {injectIntl} from 'react-intl';
import FormContext from 'formContext';

const SubMenu = ({intl, skipNavigation = false, skipBreadcrumbs = false, dynamicBreadcrumbKeyword}) => {
  const {isAuthenticated} = useContext(FormContext);
  const currentLanguage = intl.locale;
  const route = useRouteMatch();
  // Load the routes for the specific country.
  const routesCore = require(`../../../../config/core/client.routes.json`);
  let routesOpCo = {};
  try {
    routesOpCo = require(`../../../../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
  } catch (e) {
  }
  const routes = {
    ...routesCore,
    ...routesOpCo,
  }[currentLanguage];

  const navMenu = () => {
    let menuItems;

    if (!isAuthenticated) {
      menuItems = ['login', 'register', 'forgot-password', 'register-confirmation', 'manage-email-alerts'];
    } else {
      menuItems = ['dashboard', 'applications', 'profile', 'account', 'logout', 'manage-email-alerts'];
    }
    const menuItemsRenderable = [];

    menuItems.forEach((menuName, menuIndex) => {
      let isActiveMenu = false;
      if (routes.hasOwnProperty(menuName) && routes[menuName].hasOwnProperty('menuTitle')) {
        if (routes[menuName].url === route.path) {
          isActiveMenu = true;
        }
        if (routes[menuName]['menuTitle']) {
          if (menuName === 'logout') {
            let logoutRouteUrl = process.env.REACT_APP_LOGOUT_URL;
            if (currentLanguage !== process.env.REACT_APP_DEFAULT_LANGUAGE) {
              logoutRouteUrl = `${logoutRouteUrl}?destination=/${currentLanguage}`;
            }
            menuItemsRenderable.push(
                <li
                  className={`navigation__menu-item`}
                  key={`menu-item-${menuIndex}`}>
                  <a href={logoutRouteUrl}>{intl.formatMessage({id: routes[menuName]['menuTitle']})}</a>
                </li>,
            );
          } else {
            menuItemsRenderable.push(
                <li
                  className={`navigation__menu-item ${isActiveMenu ? 'navigation__menu-item--active' : ''}`}
                  key={`menu-item-${menuIndex}`}>
                  <Link
                    to={routes[menuName].url.replace(':type', '')}>
                    {intl.formatMessage({id: routes[menuName]['menuTitle']})}
                  </Link>
                </li>,
            );
          }
        }
      }
    });
    return (
      <>
        {menuItemsRenderable && <ul className="navigation__menu navigation__menu--sub hidden--until-l">{menuItemsRenderable}</ul>}
      </>
    );
  };

  return (
    <>
      <div className={`navigation ${skipNavigation ? 'navigation--skip-navigation' : 'custom-navigation'}`}>
        {!skipNavigation && navMenu()}
        {!skipBreadcrumbs && <Breadcrumbs dynamicBreadcrumbKeyword={dynamicBreadcrumbKeyword}/>}
      </div>
    </>
  );
};

export default injectIntl(SubMenu);
