import React, {useState, useContext, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {loginWithGoogle, loginWithFacebook, getApp} from 'firebaseWebHelper';
import {useCookies} from 'react-cookie';
import {pushCandidateEvent} from 'dataLayerHelper';
import getRouteByName from 'getRouteByName';
import FormContext from 'formContext';
import Spacer from '@Components/Spacer';
import axios from 'axios';
import Logger from '@ffw/logger-lib';

const SocialLogin = ({intl}) => {
  const buttonClasses = 'button button--m button--full-width';
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const currentRoute = getRouteByName('login').url;
  const downloadResumeUrl = getRouteByName('resume-builder-download').url;
  const dashboardUrl = getRouteByName('dashboard').url;
  const defaultVisibleProviders = {
    facebook: true,
    google: true,
    email: true,
  };
  // Indicates auth providers visibility.
  const [visibleProviders, setVisibleProviders] = useState(defaultVisibleProviders);
  const [isSpacerVisible, showSpacer] = useState(true);
  const [isWrongProvider, showWrongProviderMessage] = useState(false);
  // Email address that do not matches currently used provider.
  const [wrongProviderEmail, setWrongProviderEmail] = useState('');
  const {
    additionalData,
    formName,
    getFormSchema,
    handleErrors,
    setAdditionalData,
    setErrors: setFormErrors,
    setFormData,
    setFormSchema,
    triggerRerender,
  } = useContext(FormContext);

  /**
   * On visible providers change.
   */
  useEffect(() => {
    // Keep information about social login component in context.
    setAdditionalData({
      ...additionalData,
      social: {
        resetCallback: resetForm,
        // If at least one provider is hidden.
        isResettable: !!Object.values(visibleProviders).filter((v) => !v).length,
      },
    });
    // Show spacer in case if both facebook and google providers are shown.
    showSpacer(visibleProviders.facebook && visibleProviders.google);
    // Handle email provider visibility change.
    const formSchema = getFormSchema(formName);
    if (formSchema) {
      formSchema[0].data[0].components.forEach((value) => {
        if (value.component !== 'SocialLogin' && value.component !== 'SocialLoginReset') {
          // Hide all components except social login.
          value.settings.hidden = !visibleProviders.email;
        }
      });
      setFormSchema(formName, formSchema);
      triggerRerender();
    }
  }, [visibleProviders]);

  /**
   * Adds settings to field's schema in Email provider form.
   * @param {string} fieldName Field name (from input[name] attribute).
   * @param {object} settings Settings that needs to be added.
   * @return {void}
   */
  const setEmailProviderFieldSettings = (fieldName = 'email', settings = {}) => {
    const formSchema = getFormSchema(formName);
    if (formSchema) {
      formSchema[0].data[0].components.forEach((value) => {
        if (value.settings.name === fieldName) {
          value.settings = {
            ...value.settings,
            ...settings,
          };
        }
      });
      setFormSchema(formName, formSchema);
      triggerRerender();
    }
  };

  /**
   * Handles login with certain hook.
   *
   * @param {function} hook
   * @param {MouseEvent} event
   */
  const handleLoginWithHook = (hook, event) => {
    event.preventDefault();
    hook().then((userCredentials) => {
      const {refreshToken, email} = userCredentials.user;
      userCredentials.user.getIdToken().then((accessToken) => {
        // Now we have everything ready for login. Send data to API endpoint
        // for setting cookies and retrieving user data from RUSA API.
        axios
            .post(`${process.env.REACT_APP_API_PREFIX}/login`, {accessToken, refreshToken, email, rememberMe: true})
            .then(onSuccessLogin)
            .catch(handleErrors);
      });
    }).catch(handleFirebaseErrors);
  };

  /**
   * Runs hook for handling login using Google auth provider.
   *
   * @param {MouseEvent} event
   * @return {undefined}
   */
  const handleLoginWithGoogle = (event) => handleLoginWithHook(loginWithGoogle, event);

  /**
   * Runs hook for handling login using Facebook auth provider.
   *
   * @param {MouseEvent} event
   * @return {undefined}
   */
  const handleLoginWithFacebook = (event) => handleLoginWithHook(loginWithFacebook, event);

  /**
   * Firebase errors handler.
   *
   * @param {{code: string, email: string}} error Error.
   */
  const handleFirebaseErrors = (error) => {
    switch (error.code) {
      case 'auth/account-exists-with-different-credential':
        // If current login provider does not match account provider.
        getApp().auth().fetchSignInMethodsForEmail(error.email).then((methods) => {
          // Show wrong provider message.
          showWrongProviderMessage(true);
          // Reset form errors.
          setFormErrors();
          // Detect what auth method used on account.
          switch (methods[0]) {
            case 'password':
              // Hide following if email/password is the base user provider.
              setVisibleProviders({
                ...visibleProviders,
                facebook: false,
                google: false,
                email: true,
              });
              // Prefill email input field.
              setFormData([{'email': error.email}]);
              // Disable email field in email provider form.
              setEmailProviderFieldSettings('email', {disabled: true});
              break;

            case 'google.com':
              // Hide following if google is the base user provider.
              setVisibleProviders({
                ...visibleProviders,
                facebook: false,
                google: true,
                email: false,
              });
              // Set provider email input field.
              setWrongProviderEmail(error.email);
              break;

            case 'facebook.com':
              // Hide following if facebook is the base user provider.
              setVisibleProviders({
                ...visibleProviders,
                facebook: true,
                google: false,
                email: false,
              });
              // Set provider email input field.
              setWrongProviderEmail(error.email);
              break;
          }
        });
        break;

      case 'auth/popup-closed-by-user':
        setFormErrors({generalError: intl.formatMessage({id: 'Login.Message.SocialLoginPopupClosed'})});
        break;

      default:
        Logger.error(error, 'components/SocialLogin/us');
        break;
    }
  };

  /**
   * Actions for success login.
   */
  const onSuccessLogin = () => {
    pushCandidateEvent('login');
    // If logged in after resume build page go back to download resume page.
    if (cookies.ResumeBuild) {
      removeCookie('ResumeBuild', {path: currentRoute});
      window.location = downloadResumeUrl;
    } else {
      window.location = dashboardUrl;
    }
  };

  /**
   * Resets whole form to its initial state.
   *
   * @param {MouseEvent} event
   */
  const resetForm = (event) => {
    setFormErrors([]);
    setFormData([{'email': ''}]);
    setEmailProviderFieldSettings('email', {disabled: false});

    setVisibleProviders(defaultVisibleProviders);
    setWrongProviderEmail('');
    showWrongProviderMessage(false);
  };

  return (
    <div className="section-extra-services-login">
      { isWrongProvider ? <p>{intl.formatMessage({id: 'Login.Message.WrongProvider'})}</p> : null }
      <input disabled={true} value={wrongProviderEmail} hidden={!wrongProviderEmail.length} />
      { visibleProviders.facebook ?
        <button onClick={handleLoginWithFacebook} type="button" className={buttonClasses}>
          <span className="icon icon--inline">
            <svg xmlns="http://www.w3.org/2000/svg" width="1365.12" height="1365.12" viewBox="0 0 14222 14222">
              <circle cx="7111" cy="7112" r="7111" fill="#1977f3"/>
              <path d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z" fill="#fff"/>
            </svg>
          </span>
          <span className="button__text">{intl.formatMessage({id: 'Login.Form.LogInWithFacebookButton.Text'})}</span>
        </button> : null
      }
      { visibleProviders.google ?
        <button onClick={handleLoginWithGoogle} type="button" className={buttonClasses + ' mt-xs'}>
          <span className="icon icon--inline">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}}>
              <path fill="#FBBB00" d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256 c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456 C103.821,274.792,107.225,292.797,113.47,309.408z"/>
              <path fill="#518EF8" d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451 c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535 c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"/>
              <path fill="#28B446" d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512 c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771 c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"/>
              <path fill="#F14336" d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012 c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0 C318.115,0,375.068,22.126,419.404,58.936z"/>
            </svg>
          </span>
          <span className="button__text">{intl.formatMessage({id: 'Login.Form.LogInWithGoogleButton.Text'})}</span>
        </button> : null
      }
      { isSpacerVisible ?
        <Spacer intl={intl} settings={{
          text: 'Login.Form.Spacer.Text',
          width: '100%',
          margin: '45px 0 34px',
        }} /> : null
      }
    </div>
  );
};

export default injectIntl(SocialLogin);
