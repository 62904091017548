import React from "react";
import { injectIntl } from "react-intl";
import RelatedArticlesItem from "@Components/RelatedArticlesItem";

function RelatedArticles({ intl, relatedArticlesData }) {
  const renderRelatedArticles = () => {
    const articleItems = [];
    relatedArticlesData.forEach((article, index) => {
      const articleData = article['_source'];
      articleItems.push(<RelatedArticlesItem key={index} articleData={articleData}/>)
    });
    return articleItems;
  };

  return (
    <div className="block blog-overview blog-overview--grid blog-overview--carousel-on-s related-jobs bg-brand--off-white">
      <div className="block__wrapper block__wrapper--stacked wrapper">
        <div className="block__header block__header--l block__header--split">
          <h2 className="block__title">{intl.formatMessage({id: "RelatedArticlesItem.Title"})}</h2>
          <a className="block__more-link" href={'/job-seeker/career-advice/all-articles/'}>{intl.formatMessage({id: "RelatedArticlesItem.Link"})}</a>
        </div>
        <div className="block__content">
          <div className="blog-overview__wrapper" data-rs-carousel-wrapper="">
            <ul className="blog-overview__list" data-rs-carousel="blog-overview--grid">
              {renderRelatedArticles()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(RelatedArticles);
