import React, {useContext} from 'react';
import Icon from '@Components/Icon';
import FormContext from 'formContext';
import useResumeBuilderHelper from 'resumeBuilderHelper';

function ResumeBuilderCard ({title, rows, handleEdit, handleDelete, handleSkillDelete=false, handleSave}) {
  const {setFormData} = useContext(FormContext);
  const {deleteResumeDataItem} = useResumeBuilderHelper();

  const editButton = () => {
    if (!handleEdit) {
      return '';
    }

    return (
      <button className='button--clean' onClick={handleEdit}>
        <Icon type='edit' className='icon icon--hover icon--inline icon--alternative'/>
      </button>
    );
  };

  const onSkillDelete = (index) => {
    deleteResumeDataItem('skills', index);
    handleSave();
    setFormData([]);
  }

  const deleteButton = () => {
    if (!handleDelete) {
      return '';
    }

    return (
      <button className='button--clean' onClick={handleDelete}>
        <Icon type='trash' className='icon icon--hover icon--inline icon--alternative'/>
      </button>
    );
  };

  const deletePerRow = (index) => {
    if (!handleSkillDelete) {
      return ''
    }

    return (
      <button className='button--clean ml-auto' onClick={() => onSkillDelete(index)}>
        <Icon type='trash' className='icon icon--hover icon--inline icon--alternative'/>
      </button>
    );
  }

  const list = () => {
    if (!rows.length) {
      return ''
    }

    return (
      rows.map((row, i) => {
        if (!title) {
          return (
            <div key={i} className='resume-card__row'>
              <span className='icon icon--inline fill-brand--blue mr-xs'>
                <Icon type='check' />
              </span>
              {row}
              {deletePerRow(i)}
            </div>
          )
        }
        else if (row) {
          return (
            <div key={i} className='resume-card__row'>{row}</div>
          )
        }
        return ''
      })
    )
  }

  if (!title) {
    return (
      <div className='resume-card bg-brand--off-white p-s border-radius--l resume-card--listing'>
        <div className={!editButton() ? 'body--m body--full' : 'body--m'}>
          <div className="resume-card__list">
            {list()}
          </div>
          <div className='resume-card__edit-listing'>
            {editButton()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='resume-card bg-brand--off-white p-s border-radius--l'>
      <div className='resume-card__header'>
        <h3 className='resume-card__heading link-tags title--s'>
          {title}
        </h3>
        <div className='resume-card__controls'>
          {editButton()}
          {deleteButton()}
        </div>
      </div>
      <div className='body--m'>
        {list()}
      </div>
    </div>
  );
}

export default ResumeBuilderCard;
