import React from 'react';
import {injectIntl} from "react-intl";

function Button({intl, settings, ...rest}) {
  const {text, filled, icon, plain, fullWidth, medium, disabled = false, loading = false, withWrapperDiv = true, divClasses = '', hidden = false} = settings || {};

  if (hidden) {
    return null;
  }

  const classes = ['button'];
  if (filled) {
    classes.push('button--filled');
  }
  if (icon) {
    classes.push('button--icon button--full-width-to-icon');
  }
  if (plain) {
    classes.push('button--plain');
  }
  if (fullWidth) {
    classes.push('button--full-width');
  }
  if (medium) {
    classes.push('button--m');
  }
  if (loading) {
    classes.push('button--preloader button--disabled-filled button--filled')
  }

  const fieldProps = {
    className: classes.join(' ')
  };

  if (!withWrapperDiv) {
    return (
      <button {...fieldProps} disabled={disabled || loading} {...rest}>
        { loading ? <span className="dots"/> : intl.formatMessage({id: text}) }
      </button>
    )
  }
  return (
    <div className={`form-group  ${divClasses}`}>
      <button {...fieldProps} disabled={disabled || loading} {...rest}>
        { loading ? <span className="dots"/> : intl.formatMessage({id: text}) }
      </button>
    </div>
  );
}

export default injectIntl(Button);
