import React, {useEffect, useRef} from 'react';


function TabBar({children, showLine = true, wrapper = true, ...rest}) {
  const tabRef = useRef();

  useEffect(() => {
    if (!tabRef.current) return;

    const { TabBar } = require("@ffw/randstad-local-orbit/js/components/tab-bar");
    new TabBar(tabRef.current);
  }, []);

  return (
    <div className="block block--xxs" {...rest}>
      <div className={`block__wrapper block__wrapper--stacked ${wrapper ? "wrapper" : ""}`}>
        <div className="tab-bar" ref={tabRef} data-rs-tab-bar="">
          {children}
        </div>
        {showLine && <div className="tab-bar__line" />}
      </div>
    </div>
  );
}

export default TabBar;
