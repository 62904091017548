import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import useDateHelper from 'dateHelper';
import ExperienceItem from "@Components/ExperienceItem";
import FormContext from "formContext";
import axios from "axios";

function ExperienceItemList({intl, items, listType = 'workXp'}) {
  const {setFormData, setFormName, getFormSchema, handleErrors, setErrors, setModalOpen, triggerRerender, setEditedItemKey} = useContext(FormContext);
  const {customDateFormat, convertToValidDate} = useDateHelper();

  const isWorkXpListType = (type) => {
    return type === 'workXp';
  }
  const formName = isWorkXpListType(listType) ? 'user-work-xp' : 'user-education';

  const handleEditWorkXpItem = (index) => {
    const item = items[index];
    const itemProps = ['name', 'title', 'isCurrentlyEmployed', 'startDate', 'endDate'];
    let data = {};
    itemProps.forEach(key => {
      data[key] = item[key] !== undefined ? item[key] : false;
      if (key === 'isCurrentlyEmployed') {
        if (item[key] === null || item[key] === false) {
          data[key] = 'no';
        }
        else if (item[key] === true) {
          data[key] = 'yes';
        }
      }
      if (key === 'endDate' && item[key] === null) {
        data[key] = "";
      }
    });

    setEditedItemKey(index);
    setFormData([data]);
    setFormName(formName);
    setModalOpen(true);
  }

  const handleEditQualificationItem = (index) => {
    const item = items[index];
    const itemProps = ['isCurrentlyEnrolled', 'isDegreeObtained', 'major', 'startDate', 'endDate', 'level', 'school'];
    let data = {};
    itemProps.forEach(key => {
      data[key] = item[key] !== undefined ? item[key] : false;
      if ((key === 'isCurrentlyEnrolled' || key === 'isDegreeObtained')) {
        if (item[key] === null || item[key] === false) {
          data[key] = 'no';
        }
        else if (item[key] === true) {
          data[key] = 'yes';
        }
      }
      if (key === 'endDate' && item[key] === null) {
        data[key] = "";
      }
    });

    setEditedItemKey(index);
    setFormData([data]);
    setFormName(formName);
    setModalOpen(true);
  }

  const handleDeleteItem = (index) => {
    const submitApiPath = isWorkXpListType(listType) ? 'work-xp' : 'qualifications';
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/${submitApiPath}/${index}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  }

  const levelFormat = (level) => {
    const formName = 'user-education';
    const schema = getFormSchema(formName);
    const levelField = schema[0].data[0].components.find(field => field.settings.name === "level");
    const selectedLevel = levelField.settings.options.find(option => option.value === level);
    return selectedLevel ? intl.formatMessage({id: selectedLevel.label}) : '';
  };

  const parseItem = (item) => {
    let parsedItem = {...item};
    let titleProp, metaProps;
    if (isWorkXpListType(listType)) {
      titleProp = 'title';
      metaProps = [
        {name: 'name'}
      ];
    } else {
      titleProp = 'school';
      metaProps = [
        {name: 'level'},
        {name: 'major'},
        {name: 'isDegreeObtained'}
      ];
    }
    parsedItem.title = item[titleProp];
    parsedItem.meta = {};
    if (item.startDate) {
      parsedItem.meta.date = customDateFormat(convertToValidDate(item.startDate));
      if (item.isCurrentlyEnrolled === true || item.isCurrentlyEmployed === true) {
        parsedItem.meta.date = `${parsedItem.meta.date} - ${intl.formatMessage({id: 'Utils.Ongoing'})}`;
      }
      else if (item.endDate) {
        parsedItem.meta.date = `${parsedItem.meta.date} - ${customDateFormat(convertToValidDate(item.endDate))}`;
      }
    }
    metaProps.map(prop => {
      if (item[prop.name]) {
        parsedItem.meta[prop.name] = prop.translationId ?
          intl.formatMessage({id: `${prop.translationId}${item[prop.name]}`}) : item[prop.name];
      }
      if (prop.name === 'isDegreeObtained') {
        if (parsedItem.meta[prop.name]) {
          parsedItem.meta[prop.name] =  intl.formatMessage({id: 'Profile.PersonalDetails.Education.DegreeObtained.Card.Label'});
        }
      }
      if (prop.name === 'level') {
        parsedItem.meta[prop.name] = levelFormat(parsedItem.meta[prop.name]);
      }
    })

    return parsedItem;
  };

  const renderItemMeta = (meta) => {
    const metaItems = [];
    Object.keys(meta).map((key, index) => {
      metaItems.push((<li key={index}>{meta[key]}</li>));
    });

    return metaItems;
  }

  const renderItems = () => {
    const renderedItems = [];
    if (items && items.length > 0) {
      items.forEach((item, index) => {
        const parsedItem = parseItem(item);
        renderedItems.push(
          <ExperienceItem
            key={index}
            title={parsedItem.title}
            index={index}
            handleEditItem={isWorkXpListType(listType) ? handleEditWorkXpItem : handleEditQualificationItem}
            handleDeleteItem={handleDeleteItem}
            label={intl.formatMessage({id: 'Profile.PersonalDetails.ContactDetails.Modal.Title'})}
          >
            <ul className="list-item__meta">
              {renderItemMeta(parsedItem.meta)}
            </ul>
            {parsedItem.description &&
              <div className="list-item__description text--alternative">{parsedItem.description}</div>}
          </ExperienceItem>
        );
      });
    }
    return renderedItems;
  };

  return (
    <>
      <ul className="list-items list-items--card">
        {renderItems()}
      </ul>
    </>
  );
}

export default injectIntl(ExperienceItemList);
