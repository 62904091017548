import React, {useContext, useState} from 'react';
import {injectIntl} from "react-intl";
import Checkbox from "@FormElements/Checkbox";
import Button from "@FormElements/Button";
import FormContext from "formContext";

const DeleteAccountParagraph = ({intl}) => {
  const {setModalOpen, setFormName} = useContext(FormContext);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const value = event.target.checked;
    setChecked(value);
  };

  const handleDeleteAccountButtonClick = () => {
    setFormName('account-delete');
    setModalOpen(true);
    setLoading(true);
  };

  return (
    <>
      <div className="list-item__description-list">
        <p>
          {intl.formatMessage({id: 'AccountSettings.DeleteAccount.Text'})}
        </p>
        <div className="form-group form-group--selection-control">
          <Checkbox
            settings={{
              name: 'account-delete',
              label: 'AccountSettings.DeleteAccount.Checkbox.Label',
              state: {'account-delete': checked}
            }}
            onChange={handleChange}/>
          <Button onClick={handleDeleteAccountButtonClick} className="button button--m" settings={{
            text: 'AccountSettings.DeleteAccount.Button.Text',
            disabled: !checked,
            withWrapperDiv: false,
            loading : loading
          }} />
        </div>
      </div>
    </>
  );
};

export default injectIntl(DeleteAccountParagraph);
