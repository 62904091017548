import React, {Fragment} from 'react';
import Icon from "@Components/Icon";
import {injectIntl} from "react-intl";

function SkillRadioButtons({intl, settings, ...rest}) {
  const {label, state, required = true, options, errors} = settings || {};
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));

  const renderOptions = () => {
    const optionsRenderable = [];
    options.forEach((option, index) => {
      const additionalProps = {};
      if (state && state[name] && state[name] === option.value) {
        additionalProps.defaultChecked = 'checked';
      }
      else {
        additionalProps.defaultChecked = false;
      }
      optionsRenderable.push(
        <Fragment key={`skillradiobuttons-${name}-${index}`}>
          <input className="ratings__input" name={name} type="radio" id={`star${option.value}`} value={option.value} {...additionalProps} {...rest}/>
          <label className="icon icon--inline" htmlFor={`star${option.value}`} aria-label={option.label}>
            <Icon type="star-filled"/>
          </label>
          <span className="ratings__description text--alternative">{intl.formatMessage({id: `${option.label}`})}</span>
        </Fragment>
      )
    });
    return optionsRenderable;
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }

    return renderable;
  };

  return (
    <div className={`form-group form-group--input ${hasErrors ? 'form-group--error' : ''}`}>
      <div className="form__label">
        <label className="form-group__label">
          {intl.formatMessage({id: label})}
          {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      </div>
      <div className="form-group__input">
        <div className="rating-dynamic" style={{height: "30px"}}>
          <div className="ratings" role="group" aria-label="ratings">
            {renderOptions()}
          </div>
        </div>
      </div>
      {renderErrors()}
    </div>
  );
}

export default injectIntl(SkillRadioButtons);
