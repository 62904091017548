import React from "react";
import Icon from "@Components/Icon";

function ListItem({children, title, handleEdit, handleAddItem, label, divider = 'false', listModifier = 'list-item--l',}) {
  return (
    <li className={`list-item ${listModifier} ${String(divider) === 'true' ? "divider" : ""}`}>
      <div className="list-item__info">
        <div className="list-item__title list-item__title--split">
          <h3 className="list-item__header">{title}</h3>
          {handleEdit && (
            <div className="list-item__controls">
              <button className="button--clean" onClick={handleEdit}>
                <Icon type="edit" className="icon icon--inline"/>
                <span className="hidden--visually">{label}</span>
              </button>
            </div>
          )}
          {handleAddItem && (
            <a href="#" className="block__control" onClick={handleAddItem}>
              <Icon type="add" className="icon icon--inline fill-brand--blue"/>
              {label}
            </a>
          )}
        </div>
        {children}
      </div>
    </li>
  );
}

export default ListItem;
