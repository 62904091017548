import React, { useState, useEffect, useRef } from "react";
import Icon from "@Components/Icon";
import {injectIntl} from 'react-intl';
import ResumeBuilderStepForm from '@Components/ResumeBuilderStepForm';

function Accordion ({title, children, expanded = false, handleCollapse}) {
  const body = useRef(null);
  const [isExpanded, setExpanded] = useState(expanded);
  const updateHeight = () => {
    if (body && body.current) {
      if (isExpanded) {
        body.current.style.maxHeight = `${body.current.scrollHeight}px`;
      }
      else {
        body.current.style.maxHeight = 0;
      }
    }
  }

  // Collapse acc from parent.
  useEffect(() => {
    if (handleCollapse) {
      setExpanded(false);
    }
  }, [handleCollapse])

  useEffect(() => {
    updateHeight();
  }, [children, isExpanded]);

  const handleExpand = () => {
    // Set Acc state on each click.
    setExpanded(isExpanded !== true);
  };

  return (
    <>
      <div className={`collapsible__trigger ${isExpanded ? 'collapsible__trigger--expanded' : ''}`}
           data-rs-collapsible="filter"
           role="button"
           aria-expanded={isExpanded}
           onClick={handleExpand} >
        <h3 className="link-list__link">
          {title}
          <span className="icon fill-brand--blue">
            <Icon type="chevron-down" />
          </span>
        </h3>
      </div>
      <div className="body-copy collapsible__content rich-text"
           data-rs-collapsible-content={isExpanded ? 'expanded' : ''}
           aria-hidden={!isExpanded}
           ref={body}>
        {
          React.Children.map(children, child => {
            if (React.isValidElement(child) && child.type === ResumeBuilderStepForm) {
              return React.cloneElement(child, {updateHeight});
            }
            return child;
          })
        }
      </div>
    </>
  );
}

export default injectIntl(Accordion);
