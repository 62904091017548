import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import SkillItem from "@Components/SkillItem";
import Icon from "@Components/Icon";
import FormContext from "formContext";
import axios from "axios";

function SkillItemList({intl, skills, handleSkillsAdd}) {
  const {setFormData, setFormName, handleErrors, setErrors, setModalOpen, triggerRerender, setEditedItemKey} = useContext(FormContext);

  const handleSkillEdit = (index) => {
    const item = skills.items[index];
    let {name, level, key} = item;
    level = level.toString();
    setFormData([{name, level}]);
    setEditedItemKey(key);
    setFormName("user-skills");
    setModalOpen(true);
  }

  const handleSkillDelete = (index) => {
    const item = skills.items[index];
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/skills/${item.key}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  }

  const renderSkills = (isMobile = false) => {
    const skillItems = [];
    if (skills && skills.count > 0) {
      skills.items.forEach((skill, index) => {
        skillItems.push(<SkillItem index={index} handleEdit={handleSkillEdit} handleDelete={handleSkillDelete} isMobile={isMobile} {...skill}/>)
      });
    }
    return skillItems;
  };

  return (
    <>
      <div className="data-table bluex-skills-list__data-table hidden--until-l">
        <table className="data-table__table ">
          <tbody>
          {renderSkills()}
          </tbody>
        </table>
      </div>
      <a href="#" className="block__control hidden--until-l" onClick={handleSkillsAdd}>
        <Icon type="add" className="icon icon--inline fill-brand--blue"/>
        {intl.formatMessage({id: 'Profile.Preferences.Skills.Button.Add'})}
      </a>
      <ul className="list-items bluex-skills-list__list-items hidden--from-l">
        {renderSkills(true)}
        <li className="list-item divider">
          <div className="list-item__info">
            <a href="#" onClick={handleSkillsAdd} className="block__control">
              <Icon type="add" className="icon icon--inline fill-brand--blue"/>
              {intl.formatMessage({id: 'Profile.Preferences.Skills.Button.Add'})}
            </a>
          </div>
        </li>
      </ul>
    </>
  );
}

export default injectIntl(SkillItemList);
