import React from 'react';
import GetClientRoutes from 'GetClientRoutes';
import {useLocation} from 'react-router-dom';
import {getRouteByNameAndLanguage} from "getRouteByName";

const useLanguageSwitcher = () => {
  const clientRoutes = GetClientRoutes();
  const location = useLocation();

  const initLanguageSwitcher = () => {
    const languageSwitcher = document.querySelectorAll('nav a.language-link');
    if (languageSwitcher.length) {
      const currentRoute = getRouteObjectFromUrl();
      languageSwitcher.forEach(element => {
        const language = element.getAttribute('hreflang');
        if (language) {
          const languageRoute = getRouteByNameAndLanguage(currentRoute.name, language);
          if (languageRoute) {
            element.setAttribute('href', languageRoute.url.replace(':type?', ''));
          }
        }
      })
    }
  }

  const getRouteObjectFromUrl = () => {
    const url = location.pathname;
    let route = null;
    Object.keys(clientRoutes).forEach(lang => {
      Object.keys(clientRoutes[lang]).forEach(routeName => {
        if (clientRoutes[lang][routeName].hasOwnProperty('url')) {
          if (clientRoutes[lang][routeName].url.replace(':type?', '') === url) {
            route = Object.assign({name: routeName}, clientRoutes[lang][routeName]);
          }
        }
      });
    });
    return route;
  }

  return {
    initLanguageSwitcher
  }
};

export default useLanguageSwitcher;
