import React from 'react';

const CheckBoxSwitch = (props) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        className="switch__input"
        {...props}
      />
      <span className="switch__slider"></span>
    </label>
  );
};

export default CheckBoxSwitch;
