import React, {useContext, useState} from 'react';
import {injectIntl} from 'react-intl';
import axios from 'axios';
import CheckBoxSwitch from '@FormElements/CheckBoxSwitch';
import FormContext from 'formContext';
import Form from 'form';
import {pushJobAlertRemovedEvent} from 'dataLayerHelper';
import Button from '@FormElements/Button';
import Modal from '@Components/Modal';
import SubscriptionJobAlertList from '@Components/SubscriptionJobAlertList';
import ConfirmationModal from '@Components/ConfirmationModal';
import NoticeInPage from '@Components/NoticeInPage';

const TalentDashboard = ({intl, accounts, subscriptionAlerts, email, groupType, updateAccounts}) => {
  const {
    additionalData,
    currentStep,
    editedItemKey,
    formData,
    formName,
    getFormSchema,
    handleChange,
    handleErrors,
    modalOpen,
    setAdditionalData,
    setEditedItemKey,
    setErrors,
    setFormData,
    setFormName,
    setModalOpen,
    setToastMessage,
    showToast,
    timesRendered,
    triggerRerender,
  } = useContext(FormContext);

  const sgAccounts = [];
  const sgAccountWE = [];
  const sgAccountJA = [];

  const sgAccountFE = [];
  let globalSuppress = false;

  const setAccounts = (allAccounts) => {
    Object.values(allAccounts).forEach((account, indexAccount) => {
      if (account.suppressions.find((suppression) => suppression.active === true)) {
        globalSuppress = true;
      }
      if (account.name === 'RUSA') {
        switch (account.type) {
          case 'WE':
            sgAccountWE.push(account);
            break;
          case 'JA':
            sgAccountJA.push(account);
            break;
          case 'FE':
            sgAccountFE.push(account);
            break;
        }
      } else {
        if (account.suppressions) {
          sgAccounts.push(account);
        }
      }
    });
  };

  setAccounts(accounts);

  const accountState = {
    sgAccounts,
    sgAccountWE,
    sgAccountJA,
    sgAccountFE,
  };

  const [errorMessageState, setErrorMessageState] = useState(false);

  const suppressItem = (checkboxStatus, account, suppression) => {
    const data = {
      'groupType': account.subscription_type,
      'method': 'POST',
      'email': email,
      'elem': suppression,
      'account': account,
      'global': false,
    };
    if (account?.type === 'JA' || account?.type === 'FE') {
      data['jobAlerts'] = subscriptionAlerts;
    }
    if (checkboxStatus) {
      data['method'] = 'DELETE';
      data['path'] = `groups/${suppression.id}/suppressions/${email}`;
    } else {
      data['body'] = {recipient_emails: [`${email}`]};
      data['path'] = `groups/${suppression.id}/suppressions`;
    }
    axios.post(`${process.env.REACT_APP_API_PREFIX}/get-callback`, {
      callback: '@Callbacks/sendGridRequest',
      currentRoute: {},
      data: data,
    }).then((res) => {
      if (res.data.status === 500) {
        setErrorMessageState(true);
      }
    }).catch((error) => {
      setErrorMessageState(true);
    });
  };

  const suppressAccountGlobal = (checked, account) => {
    const value = checked;
    const data = {
      groupType: account.subscription_type,
      method: 'POST',
      email: email,
      account: account,
      global: true,
      audiences: groupType === 'client' ? 'findEmployees' : 'jobAlerts',
    };
    if (value) {
      // Subscribe.
      data['method'] = 'DELETE';
      data['path'] = `suppressions/global/${email}`;
    } else {
      // Unsubscribe.
      data['body'] = {recipient_emails: [`${email}`]};
      data['path'] = `suppressions/global`;
    }
    axios.post(`${process.env.REACT_APP_API_PREFIX}/get-callback`, {
      callback: '@Callbacks/sendGridRequest',
      currentRoute: {},
      data: data,
    }).then((res) => {
      if (res.data.status === 500) {
        setErrorMessageState(true);
      }
    }).catch((error) => {
      setErrorMessageState(true);
    });
  };

  const onChange = (event, account, suppression) => {
    const checkboxValue = event.currentTarget.checked;
    suppression.active = checkboxValue;
    suppression.suppressed = !checkboxValue;
    account.suppressions.forEach((item) => {
      if (item.id === suppression.id) {
        item.suppressed = suppression.suppressed;
        item.active = suppression.active;
      }
    });
    let globalUnsubscribeStatus = true;
    switch (account.key) {
      case 'jrec':
      case 'rfot':
      case 'welc':
        sgAccounts.forEach((acc, accItem) => {
          if (acc.key === account.key) {
            acc.suppressions.forEach((supp, suppItem) => {
              sgAccounts[accItem] = account;
              if (supp.suppressed === false) {
                globalUnsubscribeStatus = false;
              }
            });
            if (sgAccounts[accItem].is_global_unsubscribe !== globalUnsubscribeStatus) {
              suppressAccountGlobal(!globalUnsubscribeStatus, account);
            }
            sgAccounts[accItem].is_global_unsubscribe = globalUnsubscribeStatus;
          }
        });
        break;
      case 'webm':
        sgAccountWE.forEach((acc, accItem) => {
          acc.suppressions.forEach((supp, suppItem) => {
            if (acc.type === account.type) {
              sgAccountWE[accItem] = account;
            }
            if (supp.suppressed === false) {
              globalUnsubscribeStatus = false;
            }
          });
        });
        sgAccountJA.forEach((acc, accItem) => {
          acc.suppressions.forEach((supp, suppItem) => {
            if (acc.type === account.type) {
              sgAccountJA[accItem] = account;
            }
            if (supp.suppressed === false) {
              globalUnsubscribeStatus = false;
            }
          });
        });
        if (sgAccountJA?.[0] && sgAccountJA[0].is_global_unsubscribe !== globalUnsubscribeStatus) {
          suppressAccountGlobal(!globalUnsubscribeStatus, account);
          // sgAccountWE[0].is_global_unsubscribe = globalUnsubscribeStatus;
          sgAccountJA[0].is_global_unsubscribe = globalUnsubscribeStatus;
        }
        if (account.type === 'JA') {
          changeAlertsStatus(!checkboxValue);
        }
        break;
      case 'webcm':
        sgAccountFE.forEach((acc, accItem) => {
          acc.suppressions.forEach((supp, suppItem) => {
            if (acc.type === account.type) {
              sgAccountFE[accItem] = account;
            }
            if (supp.suppressed === false) {
              globalUnsubscribeStatus = false;
            }
          });
        });
        if (sgAccountFE[0].is_global_unsubscribe !== globalUnsubscribeStatus) {
          suppressAccountGlobal(!globalUnsubscribeStatus, account);
          sgAccountFE[0].is_global_unsubscribe = globalUnsubscribeStatus;
        }
        changeAlertsStatus(!checkboxValue);
        break;
    }

    suppressItem(checkboxValue, account, suppression);
    updateAccounts(accounts, subscriptionAlerts);
  };

  const onChangeGlobal = (event) => {
    const checkboxValue = event.currentTarget.checked;
    sgAccounts.forEach((account) => {
      account.is_global_unsubscribe = !checkboxValue;
      account.suppressions.forEach((suppression) => {
        suppression.active = checkboxValue;
        suppression.suppressed = !checkboxValue;
        suppressItem(checkboxValue, account, suppression);
      });
      suppressAccountGlobal(checkboxValue, account);
    });
    if (groupType === 'talent') {
      if (sgAccountWE?.[0]) {
        sgAccountWE[0].is_global_unsubscribe = !checkboxValue;
        sgAccountWE[0].suppressions[0].active = checkboxValue;
        sgAccountWE[0].suppressions[0].suppressed = !checkboxValue;
        suppressItem(checkboxValue, accountState.sgAccountWE[0], accountState.sgAccountWE[0].suppressions[0]);
        suppressAccountGlobal(checkboxValue, accountState.sgAccountWE[0]);
      }
      if (sgAccountJA?.[0]) {
        sgAccountJA[0].is_global_unsubscribe = !checkboxValue;
        sgAccountJA[0].suppressions[0].active = checkboxValue;
        sgAccountJA[0].suppressions[0].suppressed = !checkboxValue;
        suppressItem(checkboxValue, accountState.sgAccountJA[0], accountState.sgAccountJA[0].suppressions[0]);
        suppressAccountGlobal(checkboxValue, accountState.sgAccountJA[0]);
      }
    } else {
      if (sgAccountFE?.[0]) {
        sgAccountFE[0].is_global_unsubscribe = !checkboxValue;
        sgAccountFE[0].suppressions[0].active = checkboxValue;
        sgAccountFE[0].suppressions[0].suppressed = !checkboxValue;
        suppressItem(checkboxValue, accountState.sgAccountFE[0], accountState.sgAccountFE[0].suppressions[0]);
        suppressAccountGlobal(checkboxValue, accountState.sgAccountFE[0]);
      }
    }
    changeAlertsStatus(!checkboxValue);
  };

  const handleDeleteJobAlert = (index) => {
    const item = subscriptionAlerts.subscriptions[index];
    const data = {
      method: 'delete',
      id: item.id,
      audiences: groupType === 'client' ? 'findEmployees' : 'jobAlerts',
    };
    axios.post(`${process.env.REACT_APP_API_PREFIX}/get-callback`, {
      callback: '@Callbacks/jobAlertSubscription',
      currentRoute: {},
      data: data,
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        pushJobAlertRemovedEvent();
        setModalOpen(false);
        setFormData([]);
        setEditedItemKey('');
        setErrors();
        triggerRerender();
        updateAccounts(accounts, response.data);
      }
    }).catch((error) => {
      handleErrors(error.response.data);
    });
  };

  // Opens create form modal.
  const handleCreateJobAlert = () => {
    groupType === 'client' ? setFormName('employee-alert') : setFormName('job-alert');
    setErrors()
    setFormData([]);
    setModalOpen(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      data: formData[currentStep] ? formData[currentStep] : {},
      jobAlerts: subscriptionAlerts,
      email: email,
      audiences: groupType === 'client' ? 'findEmployees' : 'jobAlerts',
    };
    // Filters can't be changed.
    delete data['searchFilters'];
    if (formName === 'job-alert' || formName === 'employee-alert') {
      if (editedItemKey || editedItemKey === 0) {
        data['method'] = 'update';
      } else {
        data['method'] = 'create';
      }
    }

    axios.post(`${process.env.REACT_APP_API_PREFIX}/get-callback`, {
      callback: '@Callbacks/jobAlertSubscription',
      currentRoute: {},
      data: data,
    }).then((response) => {
      if (response.data.jobAlerts) {
        setModalOpen(false);
        setFormData([]);
        setErrors();
        setEditedItemKey('');
        triggerRerender();
        const eventClone = {
          currentTarget: {
            checked: true,
          },
        };
        subscriptionAlerts = response.data.jobAlerts;
        if (groupType === 'talent') {
          onChange(eventClone, accountState.sgAccountJA[0], accountState.sgAccountJA[0].suppressions[0]);
        } else {
          onChange(eventClone, accountState.sgAccountFE[0], accountState.sgAccountFE[0].suppressions[0]);
        }
      } else if (response.data.status === 400) {
        handleErrors(response.data);
      }
    }).catch((error) => {
      handleErrors(error.response.data);
      updateAccounts(accounts, subscriptionAlerts);
    });
  };

  const changeAlertsStatus = (isDisabled) => {
    const data = {
      jobAlerts: subscriptionAlerts,
      email: email,
      audiences: groupType === 'client' ? 'findEmployees' : 'jobAlerts',
      method: 'update_status',
      isDisabled: isDisabled,
    };
    axios.post(`${process.env.REACT_APP_API_PREFIX}/get-callback`, {
      callback: '@Callbacks/jobAlertSubscription',
      currentRoute: {},
      data: data,
    }).then((response) => {
      updateAccounts(accounts, response.data.jobAlerts);
    }).catch((error) => {
      setErrorMessageState(true);
    });
  };

  const modalFooter = () => (
    <Button
      onClick={handleSubmit}
      className="button button--m button--filled button--full-width"
      settings={{text: getFormSchema(formName)[currentStep].modalButton}}
    />
  );

  const onCloseModal = () => {
    setModalOpen(false);
    setFormName('');
    setFormData([]);
    setEditedItemKey('');
  };

  const renderModal = () => {
    if (!modalOpen) {
      return '';
    }

    if (!formName) {
      const deletedItemIndex = formData[currentStep]['index'];
      const title = formData[currentStep]['alertName'] || '';
      return (
        <ConfirmationModal
          title={intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Title'})}
          buttonFilled={false}
          handleConfirmation={(e) => handleDeleteJobAlert(deletedItemIndex)}
          footerButtonText={intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Footer.Button.Text'})}
        >
          <p>{intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Text'}, {title: `'${title}'`})}</p>
        </ConfirmationModal>
      );
    }

    let modalTitle = '';
    if (editedItemKey || editedItemKey === 0) {
      modalTitle = intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle});
    } else {
      modalTitle = groupType === 'client' ? intl.formatMessage({id: 'EmployeeAlerts.Button.Label'}) : intl.formatMessage({id: 'JobAlerts.Button.Label'});
    }
    return (
      <Modal
        title={modalTitle}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form
          name={formName}
          handleChange={handleChange}
          state={formData[currentStep]}
          handleSubmit={handleSubmit}
        />
      </Modal>
    );
  };

  const renderCreateJobAlerts = () => {
    return (
      <button className="button button--m button--blue mt-m" onClick={handleCreateJobAlert}>
        {intl.formatMessage({id: 'JobAlerts.JobAlert.AddNewAlert.Button.Text'})}
      </button>
    );
  };

  const renderJobAlerts = () => {
    if (subscriptionAlerts && subscriptionAlerts.subscriptions && subscriptionAlerts.subscriptions.length) {
      subscriptionAlerts.subscriptions.sort((a, b) => {
        if (a.createdDate < b.createdDate) {
          return 1;
        }
        if (a.createdDate > b.createdDate) {
          return -1;
        }
        return 0;
      });
      return (
        <>
          <div className="divider my-s" aria-hidden="true"></div>
          <SubscriptionJobAlertList jobAlerts={subscriptionAlerts} groupType={groupType}/>
        </>
      );
    }
  };

  const renderAccounts = (sgAccounts) => {
    const arrayRender = [];
    Object.values(sgAccounts).forEach((account) => {
      const itemIndex = account.key ? account.key : account.type;
      let disabled = false;
      if ((account.type === 'JA' || account.type === 'FE')
        && (!subscriptionAlerts || !subscriptionAlerts.subscriptions)) {
        disabled = true;
      }
      account.suppressions.forEach((suppression, indexSuppression) => {
        arrayRender.push(
            <div key={`${itemIndex}-${indexSuppression}`} className="card mb-m bg-brand--white p-m relative">
              <h3 className="card__title mb-s pr-xl">{suppression.name}</h3>
              <div>{suppression.description}</div>
              <CheckBoxSwitch
                name={suppression.name.toLowerCase().replace(/[ ,]+/g, '_')}
                onChange={(e) => onChange(e, account, suppression)}
                checked={suppression.active && !disabled}
                disabled={disabled}
              />
              {account.type === 'JA' || account.type === 'FE' ? renderJobAlerts() : ''}
              {account.type === 'JA' || account.type === 'FE' ? renderCreateJobAlerts() : ''}
            </div>,
        );
      });
    });
    return (
      <>
        {arrayRender}
      </>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className="pb-m">
        <NoticeInPage
          type='negative'
          text='SubscriptionDashboard.Ooops.Error.Message'
        />
      </div>
    );
  };

  return (
    <>
      {errorMessageState && renderErrorMessage()}
      <div>
        {renderAccounts(accountState.sgAccounts)}
        {renderAccounts(accountState.sgAccountWE)}
        {renderAccounts(accountState.sgAccountJA)}
        {renderAccounts(accountState.sgAccountFE)}
        <div key='item-global' className="py-m m:pl-m pr-xxl relative">
          <div>unsubscribe from all email notifications</div>
          <CheckBoxSwitch
            name='global_unsubscribe'
            onChange={(e) => onChangeGlobal(e)}
            checked={globalSuppress}
          />
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default injectIntl(TalentDashboard);
