import React, {useRef, useState, useEffect, useContext} from 'react';
import axios from "axios";
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import JobCard from "@Components/JobCard";
import { pushFavJobRemovedFromListEvent } from "dataLayerHelper";

function JobCardList({jobs, intl, showMoreVisibility, offsetPerPage, showDefaultCard, jobsPageUrl, isSlider, isRecommendedJob, hideLink}) {
  const {isAuthenticated, showToast, hideToast, setToastMessage, setToastLinks, setAdditionalData} = useContext(FormContext);

  const [offset, setOffset] = useState(1);
  const ref = useRef();

  useEffect(() => {
    if (!isSlider) return;
    if (!ref.current) return;

    // Fix window undefined issue.
    const { Carousel } = require("@ffw/randstad-local-orbit/js/components/carousel");
    new Carousel(ref.current);
  }, [jobs]);

  // Event handlers
  const onUndo = async (lastJobData) => {
    let lastDeletedJob = JSON.parse(localStorage.getItem("LastDeletedfavJob"));

    // Handle cases where there is one and no fav job left
    const { id } = lastJobData;

    // Insert item in API if a user is logged in and update local storage.
    if (isAuthenticated) {
      putToServer(id, lastDeletedJob);
    }
    else {
      updateLocalStorage({...jobs});
      increaseCounterHeader();
    }

    updateJobsState(jobs);
    hideToast();
  }

  // Updates the heart counter in header.
  const increaseCounterHeader = (inc = true) => {
    // Update fav jobs counter in header.
    let hearted = document.getElementById("bluex-nav-favorites-counter");
    if (hearted) {
      let count = hearted.textContent;
      inc ? count++ : count--;
      hearted.textContent = count;
    }
  }

  // Insert favourite jobs to server.
  const putToServer = (jobId, payload) => {
    // Remove properties from object to avoid conflict with API.
    delete payload["jobId"];
    delete payload["userId"];
    delete payload["created_timestamp"];

    axios.put(`${process.env.REACT_APP_API_PREFIX}/user/saved-jobs/${jobId}`,payload).then(response => {
      increaseCounterHeader();
      updateLocalStorage({...jobs});
    });
  }
  // Updates local storage with favorite jobs list
  const updateLocalStorage = jobs => {
    if (jobs.length < 1) return;
    localStorage.setItem("favJobs", JSON.stringify(jobs));
  }

  // Remove favourite jobs from server and trigger jobslist re render to display correct fav jobs.
  const removeFromServer = (jobId) => {
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/saved-jobs/${jobId}`).then(response => {
      removeFromLocalStorage(jobId);
    });
  }

  const removeFromLocalStorage = (jobId) => {
    const temp = [...jobs];
    const newJobs = temp.filter(item => item.jobId !== jobId);

    increaseCounterHeader(false);
    localStorage.setItem("favJobs", JSON.stringify(newJobs));
  }

  const onRemoveJob = (id) => {
    const temp = [...jobs];
    const job = temp.filter(item => item.jobId === id)[0];
    const newJobs = temp.filter(item => item.jobId !== id);
    const index = temp.findIndex(el => el.jobId === id);

    // If user is logged in, delete from both API and local storage.
    if (isAuthenticated) {
      removeFromServer(id)
    }
    else {
      removeFromLocalStorage(id);
    }

    // Update local state after removing a job.
    updateJobsState(newJobs);

    // Store last deleted job to allow undo
    localStorage.setItem("LastDeletedfavJob", JSON.stringify(job));

    pushFavJobRemovedFromListEvent();

    const lastJobData = {
      id: id,
      index: index
    };

    const links = [{
      text: intl.formatMessage({id: 'Undo'}),
      onClick: e => onUndo(lastJobData)
    }];

    // Move to remove job function
    setToastMessage(intl.formatMessage({id: 'Job.SuccessfullyRemoved'}));
    setToastLinks(links);
    showToast();
  };

  const updateJobsState = (jobs) => {
    setAdditionalData(prev => {
      return {
        ...prev,
        favouriteJobs: {
          count: jobs.length,
          jobs: jobs
        }
      }
    })
  }

  let jobsList = jobs;

  if (showMoreVisibility) {
    jobsList = jobs.slice(0, offsetPerPage * offset);
  }
  else {
    jobsList = jobs.slice(0, offsetPerPage);
  }

  const renderCards = () => {
    const renderable = [];

    jobsList.forEach((job, jobDelta) => {
      renderable.push(
        (isRecommendedJob) ?
          <JobCard job={job} key={`fav-job-${jobDelta}`} bg={'bg-brand--off-white'} hideLink={hideLink} />
          :
          <JobCard job={job} key={`fav-job-${jobDelta}`} onFavIconClick={onRemoveJob} bg={'bg-brand--off-white'} hideLink={hideLink} />
      )
    })
    return renderable;
  }

  const onShowMore = () => {
    let newOffset = offset + 1;
    setOffset(newOffset);
  };

  const ShowMore = ({ onClick, jobsList, totalJobs, offsetPerPage }) => {
    if (jobsList.length < 1 || totalJobs.length < 1) return null;
    if (jobsList.length === totalJobs.length) return null;

    const indicatorAmount = (100 * jobsList.length) / totalJobs.length;

    return (
      <div className="show-more">
        <div className="show-more__indicator">
          <div className="indicator-percentage">
            <div className="indicator-percentage__background">
              <div className="indicator-percentage__amount" style={{ width: `${indicatorAmount}%` }} />
            </div>
          </div>
        </div>
        <div className="section-separator section-separator--border">
        <span>
          {intl.formatMessage({id: 'Job.Jobs.Seen'}, {i: jobsList.length, j: totalJobs.length})}
        </span>
        </div>
        <a className="button button--m" onClick={onClick}>
          {intl.formatMessage({id: 'Show.N.More'}, {n: offsetPerPage})}
        </a>
      </div>
    );
  };

  const DefaultJobCard = ({ label }) => (
    <li className="cards__item cards__item--more bg-brand--blue" data-rs-card="">
      <a href={jobsPageUrl} className="cards__link">
        <img src="/myrandstad-app/assets/image/favorites/Binoculars_illustration_UseBackgroundBlue_RGB.svg" alt="" />
        <div className="cards__footer">
          <span className="button button--s button--full-width button--off-white">{label}</span>
        </div>
      </a>
    </li>
  );


  let cardClasses = "cards__list--format-grid";
  let dataCarousel = "";

  if (isSlider) {
    cardClasses = "cards__list--format-carousel carousel--on-s carousel--on-m";
    dataCarousel = "cards";
  }

  return (
    <>
      <div className="cards">
        <ul ref={ref} className={`cards__list ${cardClasses}`} data-rs-carousel={dataCarousel} key={jobs.length}>
          {jobs && renderCards()}
          {showDefaultCard && <DefaultJobCard label={intl.formatMessage({id: 'Job.Find.Other.Jobs'})} />}
        </ul>
      </div>
      {showMoreVisibility && (
        <ShowMore jobsList={jobsList} totalJobs={jobs} offsetPerPage={offsetPerPage} onClick={onShowMore} />
      )}
    </>
  )
}

export default injectIntl(JobCardList)
