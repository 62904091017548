import React, {useContext, useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import axios from 'axios';
import Cookies from 'js-cookie';
import Form from 'form';
import FormContext from 'formContext';
import Accordion from '@Components/Accordion';
import ResumeBuilderCard from '@Components/ResumeBuilderCard';
import useResumeBuilderHelper from 'resumeBuilderHelper';
import {pushResumeBuilderEvent} from 'dataLayerHelper';
import {Sha256} from '@aws-crypto/sha256-browser';
import {toHex} from '@aws-sdk/util-hex-encoding';
import Logger from '@ffw/logger-lib';

const ResumeBuilderContactInfo = ({intl, staticContext, onSave, onDelete}) => {
  const {formData, setFormData, handleErrors, currentStep} = useContext(FormContext);
  const [editing, setEditing] = useState(false);
  const [contactInfoData, setContactInfoData] = useState({});
  const {setResumeData, getResumeData, deleteResumeDataItem, formatLocationData} = useResumeBuilderHelper();
  const form = 'resume-builder-contact-info';
  const loggedIn = Cookies.get('MyRandInfo');

  useEffect(() => {
    const resumeData = getResumeData();
    if (resumeData && resumeData.contact_info) {
      setContactInfoData(resumeData.contact_info);
    } else {
      if (loggedIn) {
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user`, {})
            .then((userData) => {
              if (userData.data && Object.keys(userData.data).length) {
                let location = userData.data.city;
                if (location && location.length) {
                  location = userData.data.state.length ? location + ', ' + userData.data.state : location;
                } else {
                  location = userData.data.state;
                }
                const contactData = {
                  first_name: userData.data.first_name ?? '',
                  last_name: userData.data.last_name ?? '',
                  address: userData.data.address1 ?? '',
                  location: location ?? '',
                  email: userData.data.email ?? '',
                  phone: userData.data?.phones[0]?.number ?? '',
                };
                setFormData({
                  contact_info: {
                    [currentStep]: {...contactData},
                  },
                });
              }
            })
            .catch((error) => {
              Logger.error('Error getting user data: ' + error.message, 'components/ResumeBuilder/ResumeBuilderContactInfo/us');
            });
      }
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const skipField = event.target.getAttribute('data-skip-empty-field');
    if (event.target.type === 'checkbox') {
      const emptyValue = event.target.getAttribute('data-empty-field-value');
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue :
        emptyValue ? emptyValue : null;
    }

    setFormData((prev) => {
      if (!prev['contact_info'] || !prev['contact_info'][currentStep]) {
        prev['contact_info'] = {
          [currentStep]: {},
        };
      }
      prev['contact_info'][currentStep][name] = value;

      // In some cases we do not want empty values, i.e. some checkboxes.
      if (!value && skipField) {
        delete prev['contact_info'][currentStep][name];
      }
      return {
        ...prev,
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setResumeData(form, 'contact_info').then((resumeData) => {
      if (resumeData && resumeData.contact_info) {
        delete formData['contact_info'];
        setFormData({...formData});
        setContactInfoData(resumeData.contact_info);
        setEditing(false);
        onSave();
        handleErrors([]);
        const hash = new Sha256();
        hash.update(resumeData.contact_info.email);
        hash.digest().then((result) => {
          pushResumeBuilderEvent('save', 'contact', toHex(result));
        });
      }
    }).catch((error) => {
      handleErrors(error.response.data);
    });
  };

  const handleCancel = (event) => {
    setEditing(false);
    delete formData['contact_info'];
    setFormData({...formData});
  };

  const onEditHandler = (event) => {
    event.preventDefault();
    const resumeData = getResumeData();
    if (resumeData && resumeData['contact_info']) {
      setFormData({
        ...formData,
        contact_info: {
          [currentStep]: {...resumeData['contact_info']},
        },
      });
    }
    setEditing(true);
  };

  const onDeleteHandler = (event) => {
    deleteResumeDataItem('contact_info');
    delete formData['contact_info'];
    setFormData({...formData});
    setContactInfoData({});
    onDelete();
  };

  let contactInfo = '';

  if (Object.keys(contactInfoData).length && !editing) {
    const heading = `${contactInfoData.first_name} ${contactInfoData.last_name}`;
    const data = [
      contactInfoData.email,
      contactInfoData.address,
      formatLocationData(contactInfoData.location.toLowerCase(), true, contactInfoData.locationData),
      contactInfoData.phone,
    ];

    contactInfo = (
      <div className='resume-builder-steps-wrapper'>
        <ResumeBuilderCard title={heading} rows={data} handleEdit={onEditHandler} handleDelete={onDeleteHandler} />
      </div>
    );
  } else {
    const formState = formData['contact_info'] && formData['contact_info'][currentStep] ? formData['contact_info'][currentStep] : [];
    contactInfo = (
      <div className='resume-builder-steps-wrapper'>
        <Form name={form} handleChange={handleChange} state={formState} handleSubmit={handleSubmit} handleCancel={handleCancel} addCancelButton={editing} />
      </div>
    );
  }

  return (
    <li className='link-list__item'>
      <Accordion title={intl.formatMessage({id: 'ResumeBuilder.Section.ContactInfo.Title'})} children={contactInfo} expanded={true} />
    </li>
  );
};

export default injectIntl(ResumeBuilderContactInfo);
