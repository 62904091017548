import React, {createContext, useEffect, useState, useRef} from 'react';
import formsConfig from '../../../config/forms.config';
import {useLocation} from 'react-router-dom'
import Toast from "@Components/Toast";

export const FormContext = createContext({});

export const FormProvider = ({children, context, authData, messages}) => {
  const location = useLocation();
  const [schema, setSchema] = useState(formsConfig);
  let initialFormData = {};
  let initialIsAuthenticated = false;
  if (context && context.data && Object.keys(context.data).length) {
    initialFormData = context.data;
  }
  else if (typeof window !== "undefined" && window.__ROUTE_DATA__) {
    initialFormData = window.__ROUTE_DATA__;
  }
  if (authData && authData.isAuthenticated) {
    initialIsAuthenticated = authData.isAuthenticated
  }
  else if (typeof window !== "undefined" && window.__AUTH_DATA__) {
    initialIsAuthenticated = window.__AUTH_DATA__.isAuthenticated;
  }

  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState();
  const [formName, setFormName] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [additionalData, setAdditionalData] = useState(initialFormData);
  const [toast, setToast] = useState(false);
  const toastRef = useRef(toast);
  toastRef.current = toast;
  const [modalOpen, setModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastLinks, setToastLinks] = useState([]);
  const [isAuthenticated] = useState(initialIsAuthenticated);
  const [timesRendered, setTimesRendered] = useState(0);
  const [editedItemKey, setEditedItemKey] = useState('');
  const [translations, setTranslations] = useState(messages);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      setErrors();
    },
    [location]
  );

  const getFormSchema = name => {
    return schema[name];
  };

  const setFormSchema = (name, data) => {
    setSchema(prev => {
      return {
        ...prev,
        [name]: data
      }
    })
  };

  // Default change handler.
  const handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    const skipField = event.target.getAttribute('data-skip-empty-field');
    if (event.target.type === 'checkbox') {
      let emptyValue = event.target.getAttribute('data-empty-field-value');
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue :
        emptyValue ? emptyValue : null;
    }

    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep][name] = value;

      // In some cases we do not want empty values, i.e. some checkboxes.
      if (!value && skipField) {
        delete prev[currentStep][name];
      }
      return {
        ...prev
      }
    });
  };

  const handleErrors = response => {
    setErrors();
    const data = {};

    if (response.errors) {
      response.errors.map(error => (data[error.path[0]] = error.message));
      if (response.error) {
        data.generalError = response.error;
      }
      setErrors(data);
    }
    else {
      if (response.error) {
        data.generalError = response.error;
      }
      setErrors(data);
    }

    // Scroll to the first error group.
    const elements = document.querySelectorAll(".form-group--error");
    if (elements[0]) {
      elements[0].scrollIntoView();
    }
  };

  const renderStep = type => {
    let newStep;
    const currentFormSchema = getFormSchema(formName);
    if (type === 'prev') {
      newStep = currentStep-1;
    }
    else {
      newStep = currentStep+1;
    }
    if (!currentFormSchema[newStep]) {
      console.error(`Trying to render invalid step "${newStep}".`);
      return;
    }

    // Scroll to the first form rendered by React.
    const elements = document.querySelectorAll("#root form");
    if (elements[0]) {
      elements[0].scrollIntoView();
    }
    setCurrentStep(newStep);
    if (!formData.hasOwnProperty(newStep)) {
      setFormData(prev => {
        prev[newStep] = {};
        return {
          ...prev
        }
      })
    }
  };

  const showToast = (closeAfter = 5000) => {
    setToast(true);
    setTimeout(() => {
      if (toastRef.current) {
        hideToast();
      }
    }, closeAfter);
  };

  const hideToast = () => {
    setToast(false);
    setToastMessage('');
    setToastLinks([]);
  };

  const triggerRerender = () => {
    setTimesRendered(Math.random());
  };

  return (
    <FormContext.Provider value={{
      getFormSchema,
      setFormSchema,
      schema,
      additionalData,
      setAdditionalData,
      formData,
      setFormData,
      errors,
      setErrors,
      handleErrors,
      handleChange,
      currentStep,
      setCurrentStep,
      renderStep,
      formName,
      setFormName,
      showToast,
      hideToast,
      toastMessage,
      setToastMessage,
      modalOpen,
      setModalOpen,
      toastLinks,
      setToastLinks,
      isAuthenticated,
      timesRendered,
      triggerRerender,
      editedItemKey,
      setEditedItemKey,
      translations,
      setTranslations,
      loading,
      setLoading
    }}>
      {children}
      <Toast
        show={toast}
        onClose={hideToast}
        message={toastMessage}
        links={toastLinks}
      />
    </FormContext.Provider>
  );
};

export default FormContext;
