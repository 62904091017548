import React from 'react';
import { useIntl } from 'react-intl';

const useDateHelper = () => {
  let intl = useIntl();

  // Allow creating dates from dateString and timestamp.
  const createNewDate = (date) => {
    if (date && typeof date.getMonth === 'function') {
      return date;
    }

    // Check if date string contains time.
    const hasTime = /[+-]\d\d:\d\d$/.test(date);
    // If date string doesn't contain time pass time to prevent convert to local timezone.
    if (!hasTime) {
      return isNaN(date) ? new Date(`${date}T00:00:00`) : new Date(date * 1000);
    }
    return isNaN(date) ? new Date(date) : new Date(date * 1000);
  }

  /**
   * @return {string}
   */
  const customDateFormat = (dateString = '', format = 'M d Y', customIntl) => {
    if (customIntl) {
      intl = customIntl;
    }
    const date = createNewDate(dateString);
    let formattedString = '';
    switch (format) {
      case "M d Y":
        formattedString = `${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getDate()}, ${date.getFullYear()}`;
        break;
      case "d M Y":
        formattedString = `${date.getDate()} ${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getFullYear()}`;
        break;
      case "j/m/Y":
        formattedString = `${date.getDate()}/${`${date.getMonth() + 1}`.padStart(2, 0)}/${date.getFullYear()}`;
        break;
      case "d. M Y":
        formattedString = `${date.getDate()}. ${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getFullYear()}`;
        break;
      default:
        formattedString = `${date.getDate()} ${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getFullYear()}`;
        break;
    }
    return (process.env.REACT_APP_USE_LOWER_CASE === 'true') ? formattedString.toLowerCase() : formattedString;
  }

  // Conver US date format "mm-dd-yyyy" to valid date.
  const convertToValidDate = (date) => {
    if(!/^\d{1,2}\-\d{1,2}\-\d{4}$/.test(date)) {
      return false;
    }

    const parts = date.split('-');
    const day = parts[1];
    const month = parts[0];
    const year = parts[2];

    return `${year}-${month}-${day}`;
  }

  // Check if a specific date has expired.
  const dateHasExpired = (dateInfo, expirationDate = false) => {
    if (expirationDate) {
      return (createNewDate(dateInfo) > createNewDate(expirationDate));
    }
    const tomorrow = new Date();
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    const expiryDate = createNewDate(dateInfo);
    expiryDate.setHours(0);
    expiryDate.setMinutes(0);
    expiryDate.setSeconds(0);
    expiryDate.setMilliseconds(1);
    return (expiryDate < tomorrow);
  }

  // Calculate days difference between two dates
  const toDays = (startDate, endDate) => {
    let firstDate = createNewDate(startDate);
    let secondDate = createNewDate(endDate);

    let timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
    return Math.round(timeDiff / (1000 * 3600 * 24));
  }

  const timeSince = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);
    let  interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return { customDateFormat, toDays, dateHasExpired, createNewDate, convertToValidDate, timeSince }
}

export default useDateHelper;
