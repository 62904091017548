import React, {useEffect} from 'react';
import {injectIntl} from 'react-intl';

function Captcha({intl, settings, ...rest}) {
  const {errors} = settings || {};
  const hasErrors = (errors && (errors.captchaToken || errors.generalError));

  useEffect(() => {
    if (typeof window.captcha !== 'undefined') {
      window.captcha.init('mrnd_us_captcha', window.rbdCaptchaSiteKey, window.captchaType);
    }
  }, []);

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors) {
      renderable.push(
        <div key={`errorField-captchaToken`} className="form-group__feedback">Please verify that you are not a robot.</div>
      );
    }
    return renderable;
  };

  return (
    <div className="form-group">
      <div data-captcha-widget-id="mrnd_us_captcha"></div>
      {renderErrors()}
    </div>
  );

}

export default injectIntl(Captcha);
