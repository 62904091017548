import React from 'react';
import {injectIntl} from 'react-intl';
import SubscriptionJobAlertItem from '@Components/SubscriptionJobAlertItem';

const SubscriptionJobAlertList = ({intl, jobAlerts, groupType}) => {
  const renderJobAlerts = () => {
    if (!jobAlerts || !jobAlerts.subscriptions || jobAlerts.subscriptions.length === 0) {
      return null;
    }
    const alerts = [];
    jobAlerts.subscriptions.forEach((alert, index) => {
      alerts.push(<SubscriptionJobAlertItem key={alert.id} alert={alert} index={index} groupType={groupType}/>);
    });

    return (<ul className="subscription-list">{alerts}</ul>);
  };

  return (
    <>
      {renderJobAlerts()}
    </>
  );
};

export default injectIntl(SubscriptionJobAlertList);
