import React from "react";
import useDateHelper from 'dateHelper';

function RelatedArticlesItem({ intl, articleData }) {
  const { customDateFormat } = useDateHelper();
  let alt = articleData.image_alt ? articleData.image_alt[0] : '';
  let title = articleData.image_title ? articleData.image_title : '';
  let bg_svg = (articleData.color === 'dark-blue') ? 'darkblue-bg' : 'yellow-blue-red-turquoise-bg';
  const date = new Date(articleData.field_date[0]);

  return (
    <li className="blog-overview__item">
      <article className="blog-overview__article">
        {articleData.image_url ? (
          <a className="blog-overview__media aspect-ratio aspect-ratio--3-2 media-block--center" href={articleData.url}>
            <img src={articleData.image_url} alt={alt} title={title} />
          </a>
        ) : (
          <a className={`blog-overview__media blog-overview__media--border-radius blog-overview__media--s aspect-ratio aspect-ratio--3-2 bg-brand--${articleData.color}`} href={articleData.url}>
            <img src={`/themes/custom/bluex/dist/assets/image/icons.svg#${bg_svg}.svg`} alt={articleData.field_title} title={articleData.field_title} />
          </a>
        )}
        <div className="blog-overview__content">
          <a className="blog-overview__link" href={articleData.url} alt={articleData.field_title} data-rs-carousel-image="">
            <div className="blog-overview__date">{customDateFormat(date, intl)}</div>
            <h3 className="blog-overview__title">{articleData.field_title}</h3>
          </a>
        </div>
      </article>
    </li>
  );
}

export default RelatedArticlesItem;
