import React, {useState, useEffect, useRef} from 'react';
import {injectIntl} from "react-intl";

function AutoSuggestField({intl, settings, onChange, ...rest}) {
  const {label, placeholder, required = true, state, errors, noResultsMessage, description = false, divClasses = ''} = settings || {};
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef();
  const {options} = settings || [];
  const {hidden} = settings || false;
  let {name} = settings || {};
  let {readonly = false} = settings || {};
  const input = state && state.hasOwnProperty(name) ? state[name] : '';
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));
  const id = `field--autosuggest--${name}`;

  const onItemMouseEnter = (e) => {
    e.target.classList.add("select-menu__item--preselect");
  }

  const onItemMouseLeave = (e) => {
    e.target.classList.remove("select-menu__item--preselect");
  }

  const onItemClick = (e) => {
    if (!input) {
      return false;
    }
    const text = e.target.innerText;
    setMenuOpen(false);
    e.target = {
      name: name,
      value: text,
    };
    onChange(e);
  };

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!ref.current) {
        return;
      }
      // if clicked outside input field or list, close the list and do not select item
      if (!ref.current.contains(e.target) && ref.current.classList.contains('select-menu--open')) {
        setMenuOpen(false);
      }
    })
  }, [])

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label" />
    }
    else {
      return (
        <label className="form-group__label" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required &&
          <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderOptions = () => {
    const renderable = [];
    if (!options) {
      return '';
    }
    if (!input) {
      return '';
    }
    options.forEach((option, index) => {
      const escaped = input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(escaped, 'gi');
      const response = option.replace(regex, value => {
        return `<mark>${value}</mark>`;
      });
      renderable.push(
        <li
          className="select-menu__item"
          key={index}
          onMouseEnter={onItemMouseEnter}
          onMouseLeave={onItemMouseLeave}
          onClick={onItemClick}
          dangerouslySetInnerHTML={{ __html: response}}
        />
      )
    });
    if (!renderable) {
      return '';
    }
    return (
      <ul className="select-menu__list">
        {renderable}
      </ul>
    );
  };

  const handleChange = (e) => {
    setMenuOpen(true);
    onChange(e);
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  const renderDescription = () => {
    if (!description) {
      return '';
    }
    return (
      <div className="form-group__message">{intl.formatMessage({id: description})}</div>
    )
  };

  return (
    <>
      <div className={`form-group form-group--autosuggest ${hidden ? 'hidden' : ''} ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
        {renderLabel()}
        <div ref={ref} className={`form-group__input ${menuOpen ? 'select-menu--open' : ''}`}>
          <input
            type="text"
            name={name}
            id={id}
            required={required}
            readOnly={readonly}
            placeholder={placeholder && intl.formatMessage({id: placeholder}).toLowerCase()}
            value={input}
            data-rs-auto-suggest-no-result-message={noResultsMessage ? intl.formatMessage({id: noResultsMessage}) : ' '}
            data-rs-auto-suggest-input=""
            autoComplete="off"
            {...rest}
            onChange={handleChange}
          />
          {renderOptions()}
        </div>
        {renderDescription()}
        {renderErrors()}
      </div>
    </>
  )

}

export default injectIntl(AutoSuggestField);
