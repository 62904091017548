import React, {useEffect, useState} from 'react';
import wnumb from 'wnumb';
import Icon from "@Components/Icon";

const CardMeta = ({ details, intl }) => {
  const useLowerCase = process.env.REACT_APP_USE_LOWER_CASE === "true";
  // Helper function that prepares job details.
  const getCardMeta = async (details) => {
    const {
      isRemote,
      region = "",
      city = "",
      salaryData,
      jobType,
      opCoName,
    } = details || {};

    function formatSalary(value, salaryData) {
      const salaryFormat = wnumb({
        decimals: 0,
        thousand: ',',
        prefix: salaryData.currency ? salaryData.currency : process.env.REACT_APP_CURRENCY_SYMBOL
      });

      const salaryHourlyFormat = wnumb({
        decimals: 2,
        thousand: ',',
        prefix: salaryData.currency ? salaryData.currency : process.env.REACT_APP_CURRENCY_SYMBOL
      });

      return (salaryData.type === 'per hour' && !Number.isInteger(value))
        ? salaryHourlyFormat.to(parseFloat(value))
        : salaryFormat.to(parseFloat(value));
    }

    function salaryFormatter(salaryData) {
      const useLowerCase = process.env.REACT_APP_USE_LOWER_CASE === "true";
      const Salary = Object.assign({}, salaryData);
      if (parseInt(Salary.min) === 0 && parseInt(salaryData.max) === 0) {
        return null;
      }

      if (salaryData.min) {
        salaryData.min = formatSalary(parseFloat(salaryData.min), salaryData);
      }
      if (salaryData.max) {
        salaryData.max = formatSalary(parseFloat(salaryData.max), salaryData);
      }

      if (salaryData.min) {
        if (salaryData.min === salaryData.max && salaryData.type) {
          return `${salaryData.min} ${useLowerCase ? salaryData.type.toLowerCase() : salaryData.type}`;
        }
        else if (salaryData.min && salaryData.max && salaryData.type) {
          return `${salaryData.min} - ${salaryData.max} ${useLowerCase ? salaryData.type.toLowerCase() : salaryData.type}`;
        }
        else if (salaryData.min && salaryData.type) {
          return `${salaryData.min} ${useLowerCase ? salaryData.type.toLowerCase() : salaryData.type}`
        }
        else {
          return salaryData.min;
        }
      }

      if (!salaryData.min && !salaryData.max && salaryData.fixed) {
        salaryData.fixed = formatSalary(parseFloat(salaryData.fixed), salaryData);
        return `${salaryData.fixed} ${useLowerCase ? salaryData.type.toLowerCase() : salaryData.type}`
      }

      return null;
    }

    // Location separator.
    const sep = region && city ? ", " : "";
    let remote = '';
    if (isRemote) {
      remote = ` (${intl.formatMessage({id: 'JobCard.Remote.Label'})})`;
    }
    const locationText = city + sep + region + remote;
    const salaryText = salaryData ? salaryFormatter(salaryData) : "";

    const location = {
      icon: "marker",
      text: locationText
    };

    const jobTypeLine = {
      icon: "briefcase",
      text: jobType
    };

    const salary = {
      icon: "salary",
      text: salaryText
    };

    const opco = {
      icon: 'building',
      text: opCoName
    };

    // Set order to meta fields, filter empty value, and limit to {offset}.
    return [location, jobTypeLine, salary, opco].filter(i => i.text).slice(0, 4);
  }

  const [fields, setFields] = useState([]);

  useEffect(() => {
    (async () => {
      const meta = await getCardMeta(details);
      setFields(meta);
    })();
  }, []);

  return (
    <ul className="cards__meta">
      {fields.map((item, i) => (
        <li key={i} className="cards__meta-item">
          <Icon type={item.icon} className="icon icon--inline" />
          {useLowerCase && item.icon !== 'salary' ? item.text.toLowerCase() : item.text}
        </li>
      ))}
    </ul>
  );
}

export default CardMeta;
